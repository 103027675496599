// productsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const productsSlice = createSlice({
  name: "products",
  initialState: [],
  reducers: {
    setProducts(state, action) {
      return action.payload;
    },
    addProducts(state, action) {
      const formData = action.payload;
      const {
        productName,
        stage,
        objective,
        teamLead,
        isTestProduct,
        selectedTeamMembers,
        filenames,
        pdfFilenames,
        id,
      } = formData;

      const newProduct = {
        name: productName,
        stage: stage,
        objective: objective,
        team_lead: Number(teamLead),
        test_product: isTestProduct,
        teamMembers: selectedTeamMembers.map((member) => member),
        files: filenames,
        pdfs: pdfFilenames,
        id: id,
      };
      state.push(newProduct);
    },
    updateProduct(state, action) {
     
      const updatedProduct = action.payload;
      const index = state.findIndex(
        (product) => product.id === updatedProduct.id
      );
      if (index !== -1) {
        state[index] = updatedProduct;
      }
    },
    deleteProduct(state, action) {
      return state.filter((product) => product.id !== action.payload);
    },
    // Other reducers
  },
});

export const { setProducts, addProducts, updateProduct, deleteProduct } =
  productsSlice.actions;
export default productsSlice.reducer;
