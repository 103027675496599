// FE MAPPING
export const SERVICE_STATUS = {
    IDLE: 0,
    SUCCESS: 1,
    FAILED: 2,
    INPROGRESS: 3,
  };
  
  // FE MAPPING
  export const IMAGE_CATEGORY = {
    AVATAR64: 'AVATAR64',
    AVATAR128: 'AVATAR128',
    AVATAR256: 'AVATAR256',
    OTHERS: 'OTHERS',
  };
  
  export const SORT_ORDER = {
    ASC: 'ASC',
    DESC: 'DESC',
    NONE: 'NONE',
  };

  
  export const UserDateCellProps = ({ cellData, dateFormat }) => {};
  