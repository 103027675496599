
import logo from '../assets/images/logo.png';
import '../styles/_copyright.scss';
import ImageLoader from './ImageLoader';

const Copyright = () => (
  <div className="loginFooter">
    <div className="logoBottom">
    <ImageLoader name="logo.png" />
    </div>
    <p>Copyright 2022. Charaka. All Rights Reserved.</p>
  </div>
);

export default Copyright;
