import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, updateProduct } from "../redux/productsSlice";
import { IoCloseOutline } from "react-icons/io5";
import Switch from "react-switch";
import Select from "react-select";
import { FaFilePdf } from "react-icons/fa";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ImageSlider from "./ImageSlider";
import ImageLoader from "./ImageLoader";
import GeneTable from "./GeneTable";
import ProteinGeneTable from "./ProteinGeneTable";
import { PiTrash } from "react-icons/pi";
import { nanoid } from 'nanoid'
import { useNavigate } from "react-router-dom";
import apiService from "../services/apiService";
import AppConfig from "../AppConfig";
import stages from "../assets/data/stage";
import hosts from "../assets/data/host";
import tools from "../assets/data/tools";
import PDFViewer from "./PDFViewer";
import { useLocation } from 'react-router-dom';
import Footer from "./Footer";
import { BiDotsVerticalRounded } from "react-icons/bi";
// import { toastNotifyError, toastNotifySucess } from '../slices/toast';
import { toast } from 'sonner';


const EditProductForm = () => {
	const location = useLocation();
	const { product, productPhotos, productPdfs } = location.state || {};
	const photos = (productPhotos == null ? "uploads\\" : productPhotos.map((photo) => photo.replace("uploads\\", "")));
	const pdfs = (productPdfs == null ? "uploads\\" : productPdfs.map((pdf) => pdf.pdf_url.replace("uploads\\", "")));
	const [currentStep, setCurrentStep] = useState(1);
	const [editedProduct, setEditedProduct] = useState(product);
	const [selectedStage, setSelectedStage] = useState(null);
	const [selectedHost, setSelectedHost] = useState(null);
	const [selectedTools, setSelectedTools] = useState(null);
	const [files, setFiles] = useState([]);
	const [pdfFiles, setPdfFiles] = useState([]);
	const [showTeamMemberModal, setShowTeamMemberModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control delete confirmation modal
	const [isApproved, setIsApproved] = useState(editedProduct.is_approved); // State to track product approval
	const [isApprovedSequence, setIsApprovedSequence] = useState(editedProduct.is_approvedSequence); // State to track product approval
	const [isSubmitting, setIsSubmitting] = useState(false);
	const modalRef = useRef(null);
	const fileInputRef = useRef(null);
	const pdfInputRef = useRef(null);
	const users = useSelector((store) => store.users);
	const userId = localStorage.getItem('userId');
	const [pdfUrl, setPdfUrl] = useState(null);
	const [selectedPdfUrl, setSelectedPdfUrl] = useState(null); // State for selected PDF URL
	const [geneOptions, setGeneOptions] = useState([]);
	const [isGeneTableOpen, setIsGeneTableOpen] = useState(false);
	const [isProteinTableOpen, setIsProteinTableOpen] = useState(false);
	const [proteinGeneOptions, setProteinGeneOptions] = useState([]);
	const [selectedGene, setSelectedGene] = useState(editedProduct.gene);
	const [selectedProteinGene, setSelectedProteinGene] = useState(editedProduct.protein);
	const [currentRowIndex, setCurrentRowIndex] = useState(null);
	const [isOptionsVisible, setOptionsVisible] = useState(false);
	const [isExperimentModalOpen, setIsExperimentModalOpen] = useState(false);
	const [currentRowId, setCurrentRowId] = useState(null);
	const [currentExperimentName, setCurrentExperimentName] = useState('');
	const [selectedExperiment, setSelectedExperiment] = useState(null);
	const navigate = useNavigate();
	const containerStyles = {
		width: "300px",
		height: "280px",
		margin: "0 auto",
	};
	const toggleOptions = () => {
		setOptionsVisible(!isOptionsVisible);
	};
	const openPdf = (pdf) => {
		const pdfUrl = `${AppConfig.charakaApiUrl}/pdfs/download/${pdf}`;
		setSelectedPdfUrl(pdfUrl);
	};
	const selectedOpenPdf = (pdf) => {
		const pdfUrl = URL.createObjectURL(pdf);
		setSelectedPdfUrl(pdfUrl);

	};

	const [selectedTeamMembers, setSelectedTeamMembers] = useState(product.teamMembers.map(id =>
		({ value: id, label: users.find(user => user.id === id)?.name })));
	const dispatch = useDispatch();
	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);

		// Filter out files that are not jpeg, png, or webp
		const allowedFiles = selectedFiles.filter(
			(file) =>
				file.type === "image/jpeg" ||
				file.type === "image/png" ||
				file.type === "image/webp"
		);

		// Limit the number of files to a maximum of 10
		if (allowedFiles.length > 10) {
			alert("Maximum 10 files allowed.");
			return;
		}

		setFiles([...files, ...allowedFiles]);
		fileInputRef.current.value = null; // Clear the input value

	};

	const handlePdfFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		const allowedFiles = selectedFiles.filter((file) => file.type === "application/pdf");

		if (allowedFiles.length > 10) {
			alert("Maximum 10 PDF files allowed.");
			return;
		}
		setPdfFiles([...pdfFiles, ...allowedFiles]);
		pdfInputRef.current.value = null; // Clear the input value
	};
	const handleDeleteFile = (index) => {
		setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};
	const handleDeletePdf = (index) => {
		setPdfFiles((prevPdfFiles) => prevPdfFiles.filter((_, i) => i !== index));
	};

	const handleFileDrop = (e) => {
		e.preventDefault();
		const droppedFiles = Array.from(e.dataTransfer.files);

		// Filter out files that are not jpeg, png, or webp
		const allowedFiles = droppedFiles.filter(
			(file) =>
				file.type === "image/jpeg" ||
				file.type === "image/png" ||
				file.type === "image/webp"
		);

		// Limit the number of files to a maximum of 10
		if (files.length + allowedFiles.length > 10) {
			alert("Maximum 10 files allowed.");
			return;
		}

		setFiles([...files, ...allowedFiles]);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleDragEnter = (e) => {
		e.preventDefault();
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
	};

	const handleBrowseClick = () => {
		fileInputRef.current.click();
	};

	const handlePdfBrowseClick = () => {
		if (pdfInputRef.current) {
			pdfInputRef.current.click();
		}
	};

	const handleSwitchChange = (checked) => {
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			test_product: checked ? 1 : 0,
		}));
	};
	const handleIsApproved = (e) => {
		const { checked } = e.target;
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			is_approved: checked,
		}));
		setIsApproved(checked);
	};

	const handleIsApprovedSequence = (e) => {
		const { checked } = e.target;
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			is_approvedSequence: checked,
		}));
		setIsApprovedSequence(checked);
	};

	const handleToggleTeamMember = (memberId, e) => {
		e.stopPropagation();

		setEditedProduct((prevProduct) => ({
			...prevProduct,
			teamMembers: prevProduct.teamMembers.includes(memberId)
				? prevProduct.teamMembers.filter((id) => id !== memberId)
				: [...prevProduct.teamMembers, memberId],
		}));
	};
	const handleTeamLeadChange = (selectedOption) => {
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			team_lead: selectedOption.value,
		}));
	};

	const handleTeamMembersChange = (selectedOptions) => {
		setSelectedTeamMembers(selectedOptions);
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			teamMembers: selectedOptions.map((option) => option.value),
		}));
	};
	useEffect(() => {
		const foundStage = stages.find((stage) => stage.value === editedProduct.stage);
		if (foundStage) {
			setSelectedStage(foundStage);
		}
	}, [editedProduct, stages]);
	useEffect(() => {
		if (editedProduct.gene) {
			fetchGeneData(editedProduct.gene);
		} else {
			setGeneOptions([]);
		}

		if (editedProduct.protein) {
			fetchProteinGeneData(editedProduct.protein);
		} else {
			setProteinGeneOptions([]);
		}
	}, []);
	const fetchGeneData = async (gene) => {

		try {
			const response = await fetch(`${AppConfig.pfCharakaApiUrl}/gene?term=${encodeURIComponent(gene)}`);
			const data = await response.json();
			if (data.status && data.data && data.data.rows) {
				const filteredRows = data.data.rows.filter(row =>
					row.data.id.label.trim() !== '' && row.data.description.label.trim() !== ''
				);
				setGeneOptions(filteredRows);

			} else {
				setGeneOptions([]);
			}
		}
		catch (error) {
			console.error('Error fetching gene data:', error);
		}
	};
	const fetchProteinGeneData = async (proteinGene) => {
		if (proteinGene) {
			try {
				const response = await fetch(`${AppConfig.pfCharakaApiUrl}/protein?term=${encodeURIComponent(proteinGene)}`);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const data = await response.json();


				if (data.status && data.data && data.data.rows) {
					const filteredRows = data.data.rows.filter(row =>
						row.data.id.label.trim() !== '' &&
						row.data.title.label.trim() !== '' &&
						row.data.gi.label.trim() !== '' &&
						row.data.accessionVersion.label.trim() !== ''
					);
					setProteinGeneOptions(filteredRows);

				} else {
					setProteinGeneOptions([]);
				}
			} catch (error) {
				console.error('Failed to fetch data:', error);
				setProteinGeneOptions([]);
			}
		} else {
			setProteinGeneOptions([]);
		}
	};

	const handleStageChange = (selectedOption) => {
		setSelectedStage(selectedOption);
		setEditedProduct({
			...editedProduct,
			stage: selectedOption ? selectedOption.value : '',
		});
	};
	const handleToolChange = (selectedOption) => {
		setSelectedTools(selectedOption);
		setEditedProduct({
			...editedProduct,
			tools: selectedOption ? selectedOption.value : '',
		});
	};

	const handleHostChange = (selectedOption) => {
		setSelectedHost(selectedOption);

	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		const photosFormData = new FormData(); // Separate FormData for photos
		const pdfFormData = new FormData();
		files.forEach((file) => {
			formData.append("photos", file);
			photosFormData.append("photos", file); // Add files to photosFormData
		});
		pdfFiles.forEach((file) => {
			formData.append("pdfs", file);
			pdfFormData.append("pdfs", file); // Add files to photosFormData
		});
		setIsSubmitting(true);
		// Dispatch action to update the product in Redux store
		try {
			// Make an HTTP PUT request to delete the product

			const productResponse = await apiService.put(
				`/product/${product.id}`,
				editedProduct
			);

			// Handle success, e.g., close the modal and update UI
			if (productResponse.status === 200) {
				if (files.length > 0) {
					const uploadResponse = await apiService.post(
						`/photos/upload/${editedProduct.id}`,
						photosFormData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					);

					// Handle response
					if (uploadResponse.status === 200) {
						dispatch(updateProduct(editedProduct));
						// onProductEdited();
						// onClose();
					} else {
						throw new Error("Failed to upload photos");
					}
				} else {
					dispatch(updateProduct(editedProduct));
					// onProductEdited();
					// onClose();
				}
				if (pdfFiles.length > 0) {
					const uploadPdfResponse = await apiService.post(
						`/pdf/upload/${editedProduct.id}`,
						pdfFormData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					);

					if (uploadPdfResponse.status === 200) {
						dispatch(updateProduct(editedProduct));
						// onClose();
					} else {
						throw new Error("Failed to upload photos");
					}
				} else {
					dispatch(updateProduct(editedProduct));
					// onClose();
				}
				// dispatch(toastNotifySucess("Product updated successfully!"));
				toast.success("Product updated successfully!")
				navigate("/productpage");
			} else {
				throw new Error("Failed to update product");
			}
		} catch (error) {
			// Handle error
			console.error("Error updating product:", error);
			toast.error("An error occurred while updating the product.")
			// dispatch(toastNotifyError("An error occurred while updating the product.", error.response?.data?.errors || []));
		} finally {
			// Set isSubmitting to false after submission is completed (success or failure)
			setIsSubmitting(false);
		}
	};

	const handleOpenDeleteModal = () => {
		setShowDeleteModal(true);
	};

	// Function to handle closing the delete confirmation modal
	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false);
	};

	// Function to handle deleting the product
	const handleDeleteProduct = async () => {
		try {
			// Make an HTTP DELETE request to delete the product

			await apiService.delete(`/product/delete/${product.id}`);

			// Handle success, e.g., close the modal and update UI
			dispatch(deleteProduct(product.id));
			toast.success('Product deleted successfully');
			// onClose();
			navigate('/productPage');
		} catch (error) {
			// Handle error
			toast.error('failed to delete the product');
			console.error("Error deleting product:", error);
		}
	};
	const onDeletePdf = (index) => {
		const updatedPdfFiles = [...pdfFiles];
		updatedPdfFiles.splice(index, 1);
		setPdfFiles(updatedPdfFiles);
	};
	const handleNextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const handlePreviousStep = () => {
		setCurrentStep(currentStep - 1);
	};
	const handleSearch = (id) => {
		const index = editedProduct.product_experiments.findIndex((value) => value.id === id);

		const gene = editedProduct.product_experiments[index].gene;
		if (gene) {
			fetchGeneData(gene);
		}
		setIsGeneTableOpen(true);
		setCurrentRowIndex(index);
	};
	const handleSearchProtein = (id) => {
		const index = editedProduct.product_experiments.findIndex((value) => value.id === id);
		const proteinGene = editedProduct.product_experiments[index].protein;
		if (proteinGene) {
			fetchProteinGeneData(proteinGene);
		}
		setIsProteinTableOpen(true);
		setCurrentRowIndex(index);
	};
	// const handleSelectedGeneChange = (geneId) => {
	// 	setSelectedGene(geneId);
	// 	setEditedProduct(prevState => ({
	// 		...prevState,
	// 		gene: geneId
	// 	}));
	// 	console.log("Selected Gene ID in Parent:", geneId);
	// };
	const handleSelectedGeneChange = (geneId, index) => {
		setSelectedGene(geneId);
		setEditedProduct(prevState => {
			const updatedExperiments = prevState.product_experiments.map((exp, idx) => {
				if (idx === index) {
					return { ...exp, gene: geneId }; // Update the target_gene field for the specific row
				}
				return exp;
			});
			return {
				...prevState,
				product_experiments: updatedExperiments,
			};
		});
		console.log("Selected Gene ID in Parent:", geneId);
	};
	const handleSelectedProteinGeneChange = (proteinId, index) => {
		setEditedProduct(prevState => {
			const updatedExperiments = prevState.product_experiments.map((exp, idx) => {
				if (idx === index) {
					return { ...exp, protein: proteinId };
				}
				return exp;
			});
			return {
				...prevState,
				product_experiments: updatedExperiments,
			};
		});
		console.log("Selected Protein Gene ID in Parent:", proteinId);
	};
	const handleDeleteExperimentRow = (index) => {
		console.log(index, "index");

		// Mark the row as deleted
		const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
			if (idx === index) {
				return { ...exp, is_deleted: true }; // Soft delete the row
			}
			return exp;
		});

		// Update the state with filtered experiments (hide deleted ones)
		const visibleExperiments = updatedExperiments.filter(exp => !exp.is_deleted);

		setEditedProduct({ ...editedProduct, product_experiments: visibleExperiments });
	};
	const handleAddRow = () => {
		setEditedProduct(prevProduct => {
			console.log("Current product_experiments:", prevProduct.product_experiments);

			return {
				...prevProduct,
				product_experiments: [
					...(prevProduct.product_experiments || []),
					{
						id: nanoid(),
						experiment_name: '',
						common_name: '',
						scientific_name: '',
						gene: '',
						protein: '',
						is_deleted: false,
					},
				],
			};
		});
	};

	const handleBack = () => {
		navigate(-1); // Go back to the previous page
	};
	const handleCloseGeneTableModal = () => {
		setIsGeneTableOpen(false);
	}
	const handleCloseProtienTableModal = () => {
		setIsProteinTableOpen(false);
	}
	const handleCodonOptimization = (experiment, experimentName) => {
		setSelectedExperiment(experiment);
		setCurrentRowId(experiment.id);
		setCurrentExperimentName(experimentName);
		setIsExperimentModalOpen(true);
	};
	const handleCloseModal = () => {
		setIsExperimentModalOpen(false);
		setCurrentRowId(null);
		setCurrentExperimentName('');

	};
	const handleSaveModal = () => {
		// Logic to save the data
		console.log({
			rowId: currentRowId,
			experimentName: currentExperimentName,

		});
		handleCloseModal();
	};
	return (
		<>
			<div className="main-container mx-auto p-8 max-w-7xl">

				<div className="flex items-center justify-between mb-4">
					<button
						type="button"
						onClick={handleBack}
						className="border-gray-400 border text-gray-600 px-2 py-1 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center text-sm"
						style={{ width: 'fit-content' }}
					>
						<ImageLoader name="left-arrow-active.svg" className="mr-1 h-4 w-3" />
						<span className="whitespace-nowrap">Back to products</span>
					</button>

					<div className="relative">
						<button
							type="button"
							onClick={toggleOptions}
							className="text-gray-600 hover:text-gray-800 flex items-center justify-center"
						>
							<BiDotsVerticalRounded size={20} />
						</button>

						{isOptionsVisible && (
							<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-50">
								<button
									type="button"
									onClick={handleOpenDeleteModal}
									className="w-full px-4 py-2 text-left text-gray-600 hover:text-red-600 hover:bg-gray-100 flex items-center"
								>
									<PiTrash className="mr-2 h-4 w-4" />
									<span>Delete Product</span>
								</button>
							</div>
						)}
					</div>
				</div>
				<h2 className="text-2xl font-semibold mb-4">Edit Product</h2>

				<div className="flex flex-col min-h-screen">
					{/* <div className="w-full flex justify-end"> */}
					{/* <button className="hover:text-red-600" onClick={() => onClose()}>
						<IoCloseOutline size={25} />
					</button> */}
					{/* </div> */}

					<form
						className="flex flex-col flex-grow"
						onSubmit={handleSubmit}
						onDrop={handleFileDrop}
						onDragOver={handleDragOver}
						onDragEnter={handleDragEnter}
						onDragLeave={handleDragLeave}

					>
						{/* Form inputs */}
						<div className="flex-grow">
							{currentStep === 1 && (
								<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 place-content-center auto-rows-min">
									{/* Left column */}
									<div className="lg:col-span-1 grid grid-cols-2 gap-x-4 gap-y-1 auto-rows-min">
										{/* Product Name */}
										<div className="input-container h-fit col-span-2">
											<label htmlFor="name" className="block font-bold mb-1">
												Product Name:
											</label>
											<input
												required
												type="text"
												id="name"
												name="name"
												placeholder="Enter product name"
												value={editedProduct.name}
												onChange={(e) => setEditedProduct({ ...editedProduct, name: e.target.value })}
												className="border border-gray-300 p-2 rounded w-full"
											/>
										</div>
										{/* Stage */}
										<div className="col-span-2">
											<label htmlFor="stage" className="block text-black-700 font-bold mb-1">
												Stage:
											</label>
											<Select
												id="stage"
												name="stage"
												value={selectedStage}
												onChange={handleStageChange}
												options={stages.map((option) => ({
													value: option.value,
													label: option.label,
												}))}
											/>
										</div>
										{/* Objective */}
										<div className="textarea-container col-span-2 mb-2 lg:mb-0">
											<label htmlFor="objective" className="block text-gray-700 font-bold mb-1">
												Objective:
											</label>
											<textarea
												required
												placeholder="Enter objective"
												id="objective"
												name="objective"
												value={editedProduct.objective}
												onChange={(e) => setEditedProduct({ ...editedProduct, objective: e.target.value })}
												className="border border-gray-300 p-2 rounded w-full"
												rows={4}
											/>
										</div>
										{/* Team Lead */}
										<div className="flex flex-col col-span-2 mb-4 lg:mb-0">
											<div className="mb-4">
												<label htmlFor="teamLead" className="block text-black-700 font-bold mb-1">
													Team Lead:
												</label>
												<Select
													id="teamLead"
													name="teamLead"
													value={users.find(user => user.id === editedProduct.team_lead) ? { value: editedProduct.team_lead, label: users.find(user => user.id === editedProduct.team_lead).name } : null}
													onChange={handleTeamLeadChange}
													options={users.map((user) => ({
														value: user.id,
														label: user.name,
													}))}
												/>
											</div>
											<div>
												<label htmlFor="team_members" className="block text-black-700 font-bold mb-1">
													Product team members
												</label>
												<Select
													id="product_team_members"
													name="product_team_members"
													value={selectedTeamMembers}
													onChange={handleTeamMembersChange}
													options={users.map((user) => ({
														value: user.id,
														label: user.name,
													}))}
													isMulti
												/>
											</div>
										</div>
										<div className="col-span-1 mb-4 flex flex-col">
											<label htmlFor="isTestProduct" className="block text-black-700 font-bold mb-1 mr-4">
												Is Test Product:
											</label>
											<Switch
												checked={editedProduct.test_product === 1}
												onChange={handleSwitchChange}
												onColor="#7FB541"
												onHandleColor="#699F40"
												handleDiameter={25}
												uncheckedIcon={false}
												checkedIcon={false}
												height={26}
												width={48}
												className="react-switch"
											/>
										</div>
									</div>

									{/* Right column */}
									<div className="lg:col-span-1 flex flex-col gap-y-4">
										{/* Attach Photo */}
										<div>
											<label htmlFor="attachPhoto" className="block font-bold mb-1 mr-4">
												Attach Photo:
											</label>
											<button
												type="button"
												onClick={handleBrowseClick}
												className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
											>
												Upload Photos
											</button>
											<input
												type="file"
												ref={fileInputRef}
												accept=".jpg,.png,.webp"
												multiple
												onChange={handleFileChange}
												className="hidden"
											/>
										</div>

										{/* Display Selected Files (Photos) */}
										<div className="">
											{(!editedProduct.files || (editedProduct.files.length === 0)) && (
												<p className="text-green-700 text-xs" style={{ color: "#7FB541" }}>
													Up to 10 files are allowed to be uploaded with the maximum file size of 20MB per file. Extensions allowed are .jpeg, .png, and .webp.
												</p>
											)}
											{(productPhotos.length > 0 || files.length > 0) && (
												<div style={{ ...containerStyles, height: (productPhotos.length > 0 || files.length > 0) ? 'auto' : 0 }}>
													<ImageSlider
														imagesList={[
															...productPhotos.map(photo => ({
																url: `${AppConfig.charakaApiUrl}/${photo}`,
																id: photo.photo_id // identify photos uniquely
															})),
															...files.map(file => ({
																url: URL.createObjectURL(file),
																id: file.name // Using file name as ID for simplicity
															}))
														]}
													/>
												</div>
											)}
											<div className="flex flex-wrap gap-2 mt-2">
												{files.length > 0 &&
													files.map((photo, index) => (
														<div key={index} className="relative">
															<img
																src={URL.createObjectURL(photo)}
																alt={photo.name}
																className="w-20 h-20 object-cover"
															/>
															<button
																type="button"
																className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
																onClick={() => handleDeleteFile(index)}
															>
																<IoCloseOutline />
															</button>
														</div>
													))}
											</div>
										</div>
									</div>



								</div>
							)}

							{/* Right column */}
							{currentStep === 2 && (
								<div className="col-span-1 grid grid-cols-2 gap-4">
									{/* Left Column */}
									<div className="col-span-1 grid-cols-1 gap-4">
										{/* Host Organism */}
										<div className="h-fit">
											<label htmlFor="stage" className="block text-black-700 font-bold mb-1">
												Host:
											</label>
											<Select
												id="host"
												name="host"
												value={selectedHost}
												onChange={handleHostChange}
												options={hosts.map((option) => ({
													value: option.value,
													label: option.label,
												}))}
											/>
											<br></br>
										</div>

										{/* Approve Product */}

										{userId == editedProduct.team_lead && ( // Check if current user is the Team Lead
											<div className="mt-3">
												<div className="checkbox">
													<input
														type="checkbox"
														id="approval"
														checked={isApproved}
														onChange={handleIsApproved}
													/>
													<label htmlFor="approval" className="block text-black-700 font-bold mb-2">
														Approve Product:
													</label>

												</div>
											</div>
										)}
										<br></br>

									</div>

									{/* Right Column */}
									<div className="col-span-1 grid grid-cols-1 gap-4">

										{/* Upload PDF */}
										<div>
											<label htmlFor="attachPdf" className="block font-bold mb-1 mr-4">
												Attach PDF:
											</label>
											<button
												type="button"
												onClick={handlePdfBrowseClick}
												className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
											>
												Upload PDFs
											</button>
											<input
												type="file"
												id="pdfUpload"
												name="pdfUpload"
												ref={pdfInputRef}
												onChange={handlePdfFileChange}
												className="hidden"
												accept="application/pdf"
												multiple
											/>
										</div>
										{/* Render PDF Files */}
										<div className="my-3 flex flex-wrap">
											{(editedProduct.pdfs) &&
												(editedProduct.pdfs).map((pdf, index) => (
													<div key={index} className="flex items-center mb-2 cursor-pointer" onClick={() => openPdf(pdf.pdf_id)}>
														<FaFilePdf size={24} className="text-red-600 mr-2" />
														<span className="text-black">{pdf.pdf_url}</span>
													</div>
												))}
											<div className="flex flex-wrap gap-2 mt-2">
												{pdfFiles.length > 0 &&
													pdfFiles.map((pdf, index) => (
														<div key={index} className="flex items-center mb-2">
															<FaFilePdf className="text-red-500 mr-2" />
															<span className="underline cursor-pointer" onClick={() => selectedOpenPdf(pdf)}>
																{pdf.name}
															</span>
															<button
																type="button"
																className="bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
																onClick={() => handleDeletePdf(index)}
															>
																<IoCloseOutline />
															</button>
														</div>
													))}
											</div>
										</div>


									</div>
								</div>
							)}
							{currentStep === 3 && (
								<>
									<div className="flex justify-end items-center mb-4">
										<button
											type="button"
											onClick={handleAddRow}
											className="text-green-600 hover:text-green-700 px-3 py-2 rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
										>
											<ImageLoader name="plus-icon.svg" className="h-6 w-6 mr-2" alt="Add" title="Add" />
											Add Experiment
										</button>
									</div>
									<div className="grid grid-cols-1 gap-4">
										<div className="grid grid-cols-6 gap-4 items-center font-bold">
											<div className="col-span-1 font-bold">Experiment Name</div>
											<div className="col-span-1 font-bold">Common Name</div>
											<div className="col-span-1 font-bold">Scientific Name</div>
											<div className="col-span-1 font-bold">Gene</div>
											<div className="col-span-1 font-bold">Protein</div>
											<div className="col-span-1 font-bold">Action</div>
										</div>
										<div className="grid grid-cols-1 gap-4">
											{/* Map through each experiment */}
											{editedProduct.product_experiments && editedProduct.product_experiments.filter(experiment => !experiment.is_deleted)
												.map((experiment, index) => (
													<div key={experiment.id || index} className="grid grid-cols-6 gap-4">

														{/* Experiment Name */}
														<div className="input-container col-span-1">
															{/* <label htmlFor={`experimentName-${experiment.id || index}`} className="block font-bold mb-1">
															Experiment Name:
														</label> */}
															<input
																type="text"
																id={`experimentName-${experiment.id || index}`}
																name={`experimentName-${experiment.id || index}`}
																placeholder="Enter Experiment name"
																value={experiment.experiment_name || ''}
																onChange={(e) => {
																	const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
																		if (exp.id === experiment.id) {
																			return { ...exp, experiment_name: e.target.value };
																		}
																		return exp;
																	});
																	setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
																}}
																required
																className="border border-gray-300 p-2 rounded w-full"
															/>
														</div>

														{/* Common Name */}
														<div className="input-container col-span-1">
															{/* <label htmlFor={`commonName-${experiment.id || index}`} className="block font-bold mb-1">
															Common Name:
														</label> */}
															<input
																type="text"
																id={`commonName-${experiment.id || index}`}
																name={`commonName-${experiment.id || index}`}
																placeholder="Enter Common name"
																value={experiment.common_name}
																onChange={(e) => {
																	console.log(e, "commonname")
																	const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
																		console.log(editedProduct.product_experiments, "index");
																		if (exp.id === experiment.id) {
																			return { ...exp, common_name: e.target.value };
																		}
																		return exp;
																	});
																	setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
																}}
																required
																className="border border-gray-300 p-2 rounded w-full"
															/>
														</div>

														{/* Scientific Name */}
														<div className="input-container col-span-1">
															{/* <label htmlFor={`scientificName-${experiment.id || index}`} className="block font-bold mb-1">
															Scientific Name:
														</label> */}
															<input
																type="text"
																id={`scientificName-${experiment.id || index}`}
																name={`scientificName-${experiment.id || index}`}
																placeholder="Enter Scientific name"
																value={experiment.scientific_name}
																onChange={(e) => {
																	const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
																		if (exp.id === experiment.id) {
																			return { ...exp, scientific_name: e.target.value };
																		}
																		return exp;
																	});
																	setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
																}}
																required
																className="border border-gray-300 p-2 rounded w-full"
															/>
														</div>

														{/* Target Gene */}
														<div className="input-container col-span-1 relative">
															{/* <label htmlFor={`targetGene-${experiment.id || index}`} className="block font-bold mb-1">
															Gene:
														</label> */}
															<div className="flex">
																<input
																	type="text"
																	id={`targetGene-${experiment.id || index}`}
																	name={`targetGene-${experiment.id || index}`}
																	placeholder="Search for a gene"
																	value={experiment.gene}
																	onChange={(e) => {
																		const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
																			if (exp.id === experiment.id) {
																				return { ...exp, gene: e.target.value };
																			}
																			return exp;
																		});
																		setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
																	}}
																	required
																	className="border border-gray-300 p-2 rounded-l w-full"
																/>
																<button
																	type="button"
																	onClick={() => handleSearch(experiment.id)}
																	className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"
																	style={{
																		backgroundColor: '#6EB724',
																		borderRadius: '0 4px 4px 0',
																		width: '2.5rem',
																		height: '2.7rem',
																		marginTop: '1.7px',
																	}}
																>
																	<ImageLoader
																		className="imgFirst img-fluid"
																		name="search-icon.svg"
																		alt="Search"
																		title="Search"
																		style={{ width: '1.25rem', height: '1.25rem' }}
																	/>
																</button>
															</div>
														</div>

														<div className="input-container col-span-1 relative">
															{/* <label htmlFor={`proteinGene-${experiment.id || index}`} className="block font-bold mb-1">
															Protein:
														</label> */}
															<div className="flex items-center">
																<div className="flex relative w-full">
																	<input
																		type="text"
																		id={`proteinGene-${experiment.id || index}`}
																		name={`proteinGene-${experiment.id || index}`}
																		placeholder="Search for protein"
																		value={experiment.protein}
																		onChange={(e) => {
																			const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
																				if (exp.id === experiment.id) {
																					return { ...exp, protein: e.target.value };
																				}
																				return exp;
																			});
																			setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
																		}}
																		required
																		className="border border-gray-300 p-2 rounded-l w-full"
																	/>
																	<button
																		type="button"
																		onClick={() => handleSearchProtein(experiment.id)}
																		className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"
																		style={{
																			backgroundColor: '#6EB724',
																			borderRadius: '0 4px 4px 0',
																			width: '2.5rem',
																			height: '2.8rem',
																		}}
																	>
																		<ImageLoader
																			className="imgFirst img-fluid"
																			name="search-icon.svg"
																			alt="Search"
																			title="Search"
																			style={{ width: '1.25rem', height: '1.25rem' }}
																		/>
																	</button>
																</div>

															</div>
														</div>
														{/* Action */}
														<div className="input-container col-span-1 flex items-center justify-between">

															<button
																onClick={(e) => {
																	e.preventDefault();
																	handleCodonOptimization(experiment, experiment.experimentName);
																}}
																className="text-green-600 hover:text-green-700 px-2 py-1 text-sm rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
															>
																Codon Optimization
															</button>


															<button
																type="button"
																onClick={() => handleDeleteExperimentRow(index)}
																className="p-2 border-none flex items-center justify-center"
																style={{
																	backgroundColor: 'transparent',
																	color: '#FF0000',
																	width: '2.5rem',
																	height: '2.7rem',
																}}
															>
																<ImageLoader
																	className="imgFirst img-fluid"
																	name="crossGray copy.svg"
																	alt="Remove"
																	title="Remove"
																	style={{ width: '1.25rem', height: '1.25rem' }}
																/>
															</button>
														</div>
													</div>
												))}
										</div>
									</div>
									{/* Collapsible Gene Table */}
									<div className="transition-all duration-300 max-h-96">
										{isGeneTableOpen && (
											<GeneTable genes={geneOptions}
												selectedGene={selectedGene}
												onSelectedGeneChange={(geneId) => handleSelectedGeneChange(geneId, currentRowIndex)}
												onClose={handleCloseGeneTableModal} />
										)}



										{/* Collapsible Protein Gene Table */}
										<div className="transition-all duration-300 max-h-96">
											{isProteinTableOpen && (
												<ProteinGeneTable proteingenes={proteinGeneOptions}
													selectedProteinGene={selectedProteinGene}
													onSelectedProteinGeneChange={(proteinGeneId) => handleSelectedProteinGeneChange(proteinGeneId, currentRowIndex)}
													onClose={handleCloseProtienTableModal} />
											)}
										</div>
									</div>

								</>
							)}
						</div>
						{/* Buttons Section at the Bottom */}
						<div className="sticky bottom-0 left-0 right-0 bg-white p-4 flex justify-end items-center">
							{currentStep > 1 && (
								<button
									type="button"
									onClick={handlePreviousStep}
									className="border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center mr-4"
								>
									<ImageLoader name="left-arrow-active.svg" className="mr-2 h-6 w-4" />
									Previous
								</button>
							)}

							{currentStep < 3 && (
								<button
									type="button"
									onClick={handleNextStep}
									className={`border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center ${currentStep > 1 ? 'mr-4' : ''}`}
								>
									<ImageLoader name="right-arrow-active.svg" className="mr-2 h-6 w-4" />
									Next
								</button>
							)}

							{currentStep === 3 && (
								<>
									{/* <button
										type="button"
										onClick={handleOpenDeleteModal}
										className="border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-red-600 hover:border-red-600 rounded-full flex items-center mr-4"
									>
										<PiTrash className="h-6 w-4" />
									</button> */}

									<button
										type="submit"
										className="text-white px-4 py-2 rounded flex items-center"
										disabled={isSubmitting}
										style={{ background: '#7FB541', borderColor: 'transparent' }}
									>
										{isSubmitting ? "Submitting..." : "Submit"}
									</button>
								</>
							)}
						</div>


					</form>
				</div>
				{/* Team member selection modal */}
				{showTeamMemberModal && (
					<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
						<div className="bg-white p-8 rounded-md max-h-svh overflow-y-auto">
							<h2 className="text-2xl font-semibold mb-4">Select Team Members</h2>
							<div className="grid grid-cols-2 gap-4">
								{users.map((member) => (
									<label key={member.id} className="flex items-center">
										<input
											type="checkbox"
											className="mr-2"
											checked={editedProduct.teamMembers.includes(member.id)}
											onChange={(e) => handleToggleTeamMember(member.id, e)}
										/>
										{member.name}
									</label>
								))}
							</div>
							<div className="mt-4 flex justify-end">
								<button
									className="bg-green-500 text-white px-4 py-2 rounded-full"
									onClick={() => setShowTeamMemberModal(false)}
								>
									Done
								</button>
							</div>
						</div>
					</div>
				)}
				{/* Delete confirmation modal */}
				{showDeleteModal && (
					<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
						<div className="bg-white p-8 rounded-md max-h-svh overflow-y-auto">
							<h2 className="text-2xl font-semibold mb-4">Confirm Deletion</h2>
							<p className="mb-4">
								Are you sure you want to delete this product?
							</p>
							<div className="flex justify-end">
								<button
									onClick={handleCloseDeleteModal}
									className="mr-4 bg-gray-400 text-white px-4 py-2 rounded"
								>
									Cancel
								</button>
								<button
									onClick={handleDeleteProduct}
									className="bg-red-500 text-white px-4 py-2 rounded"
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				)}
				{selectedPdfUrl && (
					<div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center">
						<div className="bg-white p-4 rounded shadow-lg w-3/4 h-3/4">
							<div className="flex justify-end">
								<button
									onClick={() => setSelectedPdfUrl(null)}
									className="text-black-600"
								>
									Close
								</button>
							</div>

							<PDFViewer
								fileUrl={selectedPdfUrl}
							/>

						</div>
					</div>
				)}
				{isExperimentModalOpen && selectedExperiment && (
					<div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
						<div className="bg-white p-8 rounded-lg w-1/2">
							<h2 className="text-xl font-bold mb-4">{selectedExperiment.experiment_name}</h2>
							<div className="mb-4">
								<label htmlFor="tool" className="block text-black-700 font-bold mb-1">
									Tool:
								</label>
								<Select
									id={`tool-${selectedExperiment.id}`}
									name={`tool-${selectedExperiment.id}`}
									value={tools.find((toolOption) => toolOption.value === selectedExperiment.tool)}
									onChange={(selectedOption) => {
										const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
											console.log(exp.id, "expid");
											console.log(selectedExperiment.id, "selectedExperiment");
											if (exp.id === selectedExperiment.id) {
												return { ...exp, tool: selectedOption.value };
											}
											return exp;
										});
										setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
										setSelectedExperiment((prev) => ({ ...prev, tool: selectedOption.value }));
									}}
									options={tools}
									required
								/>
							</div>
							<div className="mb-4">
								<label className="block font-bold mb-2">CAI score</label>
								<input
									type="number"
									id={`CAIScore-${selectedExperiment.id}`}
									name={`CAIScore-${selectedExperiment.id}`}
									placeholder="Enter CAI score"
									value={selectedExperiment.cai_score}
									onChange={(e) => {
										const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
											if (exp.id === selectedExperiment.id) {
												return { ...exp, cai_score: e.target.value };
											}
											return exp;
										});
										setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
										setSelectedExperiment((prev) => ({ ...prev, cai_score: e.target.value }));
									}}
									required
									className="border border-gray-300 p-2 rounded w-full"
								/>

							</div>
							<div className="mb-4">
								<label className="block font-bold mb-2">GC%</label>
								<input
									type="number"
									id={`gcPercentage-${selectedExperiment.id}`}
									name={`gcPercentage-${selectedExperiment.id}`}
									placeholder="Enter GC percentage"
									value={selectedExperiment.gc_percentage}
									onChange={(e) => {
										const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
											if (exp.id === selectedExperiment.id) {
												return { ...exp, gc_percentage: e.target.value };
											}
											return exp;
										});
										setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
										setSelectedExperiment((prev) => ({ ...prev, gc_percentage: e.target.value }));
									}}
									required
									className="border border-gray-300 p-2 rounded w-full"
								/>
							</div>
							<div className="mb-4">
								<label className="block font-bold mb-2">Optimised sequence</label>
								<textarea
									className="border border-gray-300 p-2 rounded w-full"
									value={selectedExperiment.optimised_sequence}
									onChange={(e) => {
										const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
											if (exp.id === selectedExperiment.id) {
												return { ...exp, optimised_sequence: e.target.value };
											}
											return exp;
										});
										setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
										setSelectedExperiment((prev) => ({ ...prev, optimised_sequence: e.target.value }));
									}}
									onKeyDown={(e) => {
										// Allow only Ctrl+V (paste), Ctrl+Z (undo), and navigation keys (e.g., arrows, Home, End)
										if (
											(e.ctrlKey || e.metaKey) && (e.key === 'v' || e.key === 'z') || // Allow Ctrl+V and Ctrl+Z
											e.key === 'Backspace' || e.key === 'Delete' || // Allow Backspace and Delete for clearing
											e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || // Allow arrow keys for navigation
											e.key === 'Home' || e.key === 'End' // Allow Home and End for navigation
										) {
											return; // Allow these keys
										} else {
											e.preventDefault(); // Prevent all other typing
										}
									}}
									onPaste={(e) => {
										e.stopPropagation(); // Allow pasting
									}}
									required
									placeholder="Paste optimised sequence"
								/>
							</div>

							{userId == editedProduct.team_lead && (
								<div className="mb-6">

									<div className="checkbox">
										<input
											type="checkbox"
											id="selectedSequence"
											checked={selectedExperiment.selected_sequence} // Reflect the current checkbox state
											onChange={(e) => {
												// Update the selectedExperiment state with the new checkbox value
												setSelectedExperiment((prev) => ({
													...prev,
													selected_sequence: e.target.checked
												}));

												// Update the editedProduct state with the new checkbox value
												const updatedExperiments = editedProduct.product_experiments.map((exp) => {
													if (exp.id === selectedExperiment.id) {
														return { ...exp, selected_sequence: e.target.checked };
													}
													return exp;
												});
												setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
											}}
										/>
										<label htmlFor="selectedSequence" className="block text-black-700 font-bold mb-2">Selected sequence</label>
									</div>
								</div>
							)}
							<div className="flex justify-end">
								<button
									type="button"
									className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
									onClick={handleCloseModal}
								>
									Cancel
								</button>
								<button
									type="button"
									onClick={handleSaveModal}
									className="p-2 w-24 font-bold text-white rounded"
									style={{
										background: '#7FB541',
										borderColor: 'transparent',
									}}
								>
									Save
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
			<Footer></Footer>
		</>
	);
};

export default EditProductForm;