import { USER_MSG } from './messages';

export const validatePassword = (password) => {
  const alphabetRegExp = /(?=.*?[a-zA-Z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[@$.!%*#?&])/;
  const minLengthRegExp = /.{6,}/;
  const whiteSpaceRegExp = /\s/g;
  const passwordLength = password.length;
  const alphabetPassword = alphabetRegExp.test(password);
  const digitsPassword = digitsRegExp.test(password);
  const specialCharPassword = specialCharRegExp.test(password);
  const minLengthPassword = minLengthRegExp.test(password);
  const whiteSpacePassword = whiteSpaceRegExp.test(password);

  let passwordErrorMsg = '';
  let isValidPassword = false;
  const message = USER_MSG.VALID_PASSWORD;

  if (passwordLength === 0) {
    passwordErrorMsg = USER_MSG.ENTER_PASSWORD;
  } else if (whiteSpacePassword) {
    passwordErrorMsg = USER_MSG.EMPTY_SPACE_NO;
  } else if (!alphabetPassword) {
    passwordErrorMsg = message;
  } else if (!digitsPassword) {
    passwordErrorMsg = message;
  } else if (!specialCharPassword) {
    passwordErrorMsg = message;
  } else if (!minLengthPassword) {
    passwordErrorMsg = message;
  } else {
    isValidPassword = true;
  }

  return { passwordErrorMsg, isValidPassword };
};

export const validateEmail = (email) => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (!emailRegex.test(email)) {
    return false;
  } else {
    return true;
  }
};
export const validateOTP = (otp) => {
  const otpRegex = /^[0-9]{6,6}$/;
  let otpError = '';
  let isValidOtp = false;
  if (!otp) {
    otpError = USER_MSG.ENTER_OTP;
  } else if (!otpRegex.test(otp)) {
    otpError = USER_MSG.INVALID_OTP;
  } else {
    isValidOtp = true;
  }

  return { otpError, isValidOtp };
};

export const validateName = (name) => {
  const nameRegx = /^[a-zA-Z ]*$/;
  const lengthRegExp = /^[a-zA-Z ]{0,20}$/;

  let nameError = '';
  let isNameValid = false;

  if (!nameRegx.test(name)) {
    nameError = USER_MSG.ONLY_ALPHABETS;
  } else if (!lengthRegExp.test(name)) {
    nameError = USER_MSG.CHAR_LIMIT_20;
  } else {
    isNameValid = true;
  }

  return { nameError, isNameValid };
};


