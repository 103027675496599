import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../slices/auth';
import {
  clearError,
  forgotPassword,
  forgotPasswordFailed,
} from '../../slices/password';
import { validateEmail } from '../../utils/validation';
import { SERVICE_STATUS } from '../../utils/types';
import { USER_MSG } from '../../utils/messages';
import ImageLoader from '../ImageLoader';
import Copyright from '../Copyright';

const ForgotPassword = () => {
  const passwordStatus = useSelector((state) => state.password.status);
  const errorMsg = useSelector((state) => state.password.errorMsg);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');

    if (!validateEmail(email)) {
      dispatch(forgotPasswordFailed(USER_MSG.INVALID_EMAIL));
    } else {
      dispatch(
        forgotPassword(email, () => {
          navigate('/verifyotp');
        })
      );
    }
  };

  const handleEmail = (event) => {
    const email = event.target.value;
    if (!validateEmail(email)) {
      dispatch(forgotPasswordFailed('Please enter a valid email'));
    } else {
      dispatch(clearError());
    }
  };

  return (
    <>
      <div className="mainBg-Section">
        <ImageLoader name="bg-main.jpg" className="fixedBg" />
        <div className="loginBg-shapes">
          <ImageLoader name="shape.svg" />
        </div>
        <div className="loginConatiner">
          <form className="loginSection" onSubmit={handleSubmit}>
            <h2 className="loginHead">Forgot Password?</h2>
            <p className="forgotInfo">
              Don't worry, it happens. Let us know your email address and we'll send you an email with instructions.
            </p>
            <ul className="login-form">
              <li>
                <input
                  className={errorMsg ? `error-Danger` : ''}
                  type="text"
                  name="email"
                  placeholder="Email address"
                  onChange={handleEmail}
                />
                {errorMsg && <p className="error-msz">{errorMsg}</p>}
              </li>
              <li>
                <Link to="/login" className="forgotPass">
                  Back to Login
                </Link>
              </li>
            </ul>
            <div className="btnSet">
              <button
                type="submit"
                className="btn btn-big"
                disabled={passwordStatus === SERVICE_STATUS.INPROGRESS}
              >
                Send
              </button>
            </div>
          </form>
          <Copyright />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
