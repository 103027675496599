import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toastState: {
    toastType: '',
    content: '',
    errors: [],
  },
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    clearState: (state) => {
      state.toastState.toastType = '';
      state.toastState.content = '';
    },
    notifySuccess: (state, action) => {
      state.toastState.toastType = 'success';
      state.toastState.content = action.payload;
    },
    notifyError: (state, action) => {
      state.toastState.toastType = 'error';
      state.toastState.content = action.payload.data;
      state.toastState.errors = action.payload.errors;
    },
  },
});

export const { clearState, notifySuccess, notifyError } = toastSlice.actions;

export const toastNotifySucess = (data) => (dispatch) => {
  dispatch(notifySuccess(data));
};

export const toastNotifyError =
  (message, errors) => (dispatch) => {
    dispatch(notifyError({ data: message, errors: errors }));
  };

export const removeNotification = () => (dispatch) => {
  dispatch(clearState());
};

export default toastSlice.reducer;
