import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearError,
  forgotPassword,
  resetPassword,
} from '../../slices/password';
import { validateOTP, validatePassword } from '../../utils/validation';
import { SERVICE_STATUS } from '../../utils/types';
import { USER_MSG } from '../../utils/messages';
import ImageLoader from '../ImageLoader';
import Copyright from '../Copyright';

const VerifyOtp = () => {
  const errorMsg = useSelector((state) => state.password.errorMsg);
  const passwordErrors = useSelector((state) => state.password.passwordErrors || {});
  const passwordStatus = useSelector((state) => state.password.status);
  const email = useSelector((state) => state.password.email);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [inputResetPassword, setInputResetPassword] = useState({
    otp: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [resetPasswordError, setResetPasswordError] = useState({
    otp: '',
    newPassword: '',
    confirmPassword: '',
  });

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  useEffect(() => {
    Object.keys(passwordErrors).map((key) => {
      setResetPasswordError((prev) => ({
        ...prev,
        [passwordErrors[key].param]: passwordErrors[key].msg,
      }));
    });
  }, [passwordErrors]);

  const onInputPasswordChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;
    if (name === 'otp') {
      value = event.target.value.replace(/\D/, '');
    }
    setInputResetPassword((prev) => ({ ...prev, [name]: value }));
    validateResetPassInput(name, value);
  };

  const validateResetPassInput = (name, value) => {
    if (errorMsg) {
      dispatch(clearError());
    }
    setResetPasswordError((prev) => {
      const stateObj = { ...prev, [name]: '' };

      switch (name) {
        case 'otp':
          const validOtp = validateOTP(value);
          if (!validOtp.isValidOtp) {
            stateObj['otp'] = validOtp.otpError;
          }
          break;

        case 'newPassword':
          const validPass = validatePassword(value);
          if (!validPass.isValidPassword) {
            stateObj['newPassword'] = validPass.passwordErrorMsg;
          } else if (
            inputResetPassword.confirmPassword &&
            value !== inputResetPassword.confirmPassword
          ) {
            stateObj['confirmPassword'] = USER_MSG.PASSWORD_NO_MATCH;
          } else {
            stateObj['confirmPassword'] = inputResetPassword.confirmPassword
              ? ''
              : resetPasswordError && resetPasswordError.confirmPassword;
          }

          stateObj['newPassword'] = validPass.passwordErrorMsg;
          break;

        case 'confirmPassword':
          if (!value) {
            stateObj['confirmPassword'] = USER_MSG.ENTER_CONFIRM_PASSWORD;
          } else if (
            inputResetPassword.newPassword &&
            value !== inputResetPassword.newPassword
          ) {
            stateObj['confirmPassword'] = USER_MSG.PASSWORD_NO_MATCH;
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const resetDetails = { email, ...inputResetPassword };
    let isEmptyFound = false;

    Object.keys(inputResetPassword).map(function (key) {
      if (!inputResetPassword[key]) {
        isEmptyFound = true;
        validateResetPassInput(
          key,
          inputResetPassword[key]
        );
      }
    });

    if (isEmptyFound) return false;

    if (
      resetPasswordError.otp ||
      resetPasswordError.newPassword ||
      resetPasswordError.confirmPassword
    ) {
      return false;
    }

    dispatch(
      resetPassword(resetDetails, () => {
        navigate('/login');
      })
    );
  };

  const resendOtp = () => {
    dispatch(
      forgotPassword(email, () => {
        <Navigate to="/verifyotp" />;
      })
    );
  };

  if (!email) return <Navigate to="/login" replace={true} />;

  return (
    <div className="mainBg-Section">
      <ImageLoader name="bg-main.jpg" className="fixedBg" />
      <div className="loginBg-shapes">
        <ImageLoader name="shape.svg" />
      </div>
      <div className="loginConatiner verifyOtp">
        <form className="loginSection" onSubmit={handleSubmit}>
          <h2 className="loginHead">Reset Password</h2>
          <p className="forgotInfo">
            Please enter the OTP sent to your email {email}
          </p>
          <ul className="login-form">
            <li>
              <input
                type="number"
                name="otp"
                placeholder="Enter OTP"
                onChange={onInputPasswordChange}
                onBlur={(e) =>
                  validateResetPassInput(e.target.name, e.target.value)
                }
                className={
                  (errorMsg || resetPasswordError.otp) && 'error-Danger'
                }
              />
              {(resetPasswordError.otp || errorMsg) && (
                <p className="error-msz">
                  {resetPasswordError.otp || errorMsg}
                </p>
              )}
            </li>
            <li>
              <input
                type={showPassword ? 'text' : 'password'}
                name="newPassword"
                autoComplete="new-password"
                placeholder="Enter New Password"
                onChange={onInputPasswordChange}
                onBlur={(e) =>
                  validateResetPassInput(e.target.name, e.target.value)
                }
                className={
                  resetPasswordError &&
                  resetPasswordError.newPassword &&
                  'error-Danger'
                }
              />
              <strong
                aria-hidden
                onClick={() => setShowPassword(!showPassword)}
              >
                <ImageLoader className="eye-icon" name="eye.svg" />
              </strong>
              {resetPasswordError && resetPasswordError.newPassword && (
                <p className="error-msz">{resetPasswordError.newPassword}</p>
              )}
            </li>
            <li>
              <input
                type="password"
                autoComplete="off"
                name="confirmPassword"
                placeholder="Confirm Password"
                onChange={onInputPasswordChange}
                onBlur={(e) =>
                  validateResetPassInput(e.target.name, e.target.value)
                }
                className={
                  resetPasswordError &&
                  resetPasswordError.confirmPassword &&
                  'error-Danger'
                }
              />
              {resetPasswordError && resetPasswordError.confirmPassword && (
                <p className="error-msz">
                  {resetPasswordError.confirmPassword}
                </p>
              )}
            </li>
            <li>
              <Link to="#" onClick={() => resendOtp()} className="forgotPass">
                Resend OTP
              </Link>
            </li>
          </ul>
          <div className="btnSet">
            <button
              type="submit"
              className="btn btn-big"
              disabled={passwordStatus === SERVICE_STATUS.INPROGRESS}
            >
              Confirm
            </button>
          </div>
        </form>
        <Copyright />
      </div>
    </div>
  );
};

export default VerifyOtp;
