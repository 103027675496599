import { createSlice } from '@reduxjs/toolkit';

import { SERVICE_STATUS } from '../utils/types';
import * as AuthService from '../services/auth';
import { setUser } from '../redux/usersSlice';


const initialState = {
  status: SERVICE_STATUS.FAILED,
  errorMsg: '',
  successMsg: '',
  userDetails: null,
  profileStatus: SERVICE_STATUS.IDLE,
  authRoles: [],
  authRolesLabel: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMsg = '';
    },
    logout: () => {},
    loginFailed: (state, action) => {
      state.errorMsg = action.payload;
      state.userDetails = null;
      state.status = SERVICE_STATUS.FAILED;
    },
    loginSucceeded: (state, action) => {
      state.userDetails = action.payload;
      state.authRoles = action.payload.roleIds;
      state.authRolesLabel = action.payload.userRole;
      state.status = SERVICE_STATUS.SUCCESS;
    },
    loginInProgress: (state) => {
      state.errorMsg = '';
      state.successMsg = '';
      state.userDetails = null;
      state.status = SERVICE_STATUS.INPROGRESS;
    }
  },
});

export const { clearError,
  logout, 
  loginFailed, 
  loginSucceeded, 
  loginInProgress, 
  profileUpdateInProgress,
  profileUpdateFailed,
  profileUpdateSucceded,
  getUpdatedAuthProfileSucceded, } = authSlice.actions;

  export const login =
  (username, password, callback) => async (dispatch) => {
    dispatch(loginInProgress());
    const response = await AuthService.login(username, password);
    if (!response.status) {
      dispatch(loginFailed(response));
    } else {
      const data = response.data;
      const  token  = data.token;
      localStorage.setItem('token', token);
      localStorage.setItem('userId', data.id);
      dispatch(loginSucceeded(data));
      callback();
    }
  };





// Selectors
export const selectAuthStatus = (state) => state.auth.status;

export default authSlice.reducer;