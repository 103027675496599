import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers(state, action) {
      return action.payload.map(user => ({
        ...user,
        name: `${capitalize(user.first_name)}${user.last_name ? ' ' + capitalize(user.last_name) : ''}`
      }));
    },
    addUser(state, action) {
      state.push(action.payload);
    },
  },
});


// Helper function to capitalize the first letter of a string
function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const { setUser,setUsers, addUser } = usersSlice.actions;
export default usersSlice.reducer;
