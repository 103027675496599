import React from 'react';
import '../styles/_footer.scss'



const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
      <p>Copyright 2022. Charaka. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
