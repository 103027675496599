import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/_login.scss';
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks';
import {
  login,
  logout,
  } from '../../slices/auth';
// import { useDispatch } from 'react-redux';
// import { setUser } from '../redux/usersSlice';


import { USER_MSG } from '../../utils/messages';
import { SERVICE_STATUS } from '../../utils/types';
import { validateEmail } from '../../utils/validation';
import  ImageLoader  from '../ImageLoader';

import Copyright from '../Copyright';


// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';



const SignIn = () => {
  const dispatch = useAppDispatch();
  
  const navigate = useNavigate();
  
  
  const [showPassword, setShowPassword] = useState(false);
  const [inputLoginError, setInputLoginError] = useState({
    email: '',
    password: '',
  });
  const [inputLogin, setInputLogin] = useState({
    email: '',
    password: '',
  });

  const authStatus = useAppSelector((state) => state.auth.status);
  
  const errorMsg = useAppSelector((state) => state.auth && state.auth.errorMsg);
 

  const onLoginInputChange = (event) => {
    const { name, value } = event.target;
    setInputLogin((prev) => ({ ...prev, [name]: value }));
    validateLoginInput(name, value);
  };

  const validateLoginInput = (name, value) => {
    setInputLoginError((prev) => {
      const stateObj = { ...prev, [name]: '' };
      switch (name) {
        case 'email':
          if (!value) {
            stateObj['email'] = USER_MSG.ENTER_EMAIL;
          } else if (!validateEmail(value)) {
            stateObj['email'] = USER_MSG.INVALID_EMAIL;
          }
          break;

        case 'password':
          if (!value) {
            stateObj['password'] = USER_MSG.ENTER_PASSWORD;
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    dispatch(logout());
  }, [dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let isEmptyFound = false;

    Object.keys(inputLogin).forEach((key) => {
      if (!inputLogin[key]) {
        isEmptyFound = true;
        validateLoginInput(key, inputLogin[key]);
      }
    });

    if (!isEmptyFound && !inputLoginError.email && !inputLoginError.password) {
      dispatch(
        login(inputLogin.email, inputLogin.password, () => {
        navigate('/productPage');
        })
      );
    }
  };

  const passwordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ImageLoader name="bg-main.jpg" className="fixedBg" />
      <div className="loginBg-shapes">
        <ImageLoader name="shape.svg" />
      </div>
      <div className="loginConatiner">
        <form className="loginSection" onSubmit={handleSubmit}>
          <h2 className="loginHead">Sign In</h2>
          <ul className="login-form">
            <li>
              <input
                onChange={onLoginInputChange}
                className={
                  authStatus === SERVICE_STATUS.FAILED &&
                  (errorMsg.code === 'AUTH0005' ||
                    errorMsg.code === 'AUTH0008' ||
                    errorMsg.code === 'AUTH0003' ||
                    inputLoginError.email)
                    ? `error-Danger`
                    : ''
                }
                type="text"
                name="email"
                placeholder="Email*"
                
              />
              {authStatus === SERVICE_STATUS.FAILED &&
                (errorMsg.code === 'AUTH0005' ||
                  errorMsg.code === 'AUTH0008' ||
                  errorMsg.code === 'AUTH0003' ||
                  inputLoginError.email) && (
                  <p className="error-msz">
                    {errorMsg.code === 'AUTH0003'
                      ? USER_MSG.INVALID_ROLE
                      : errorMsg.message || inputLoginError.email}
                  </p>
                )}
            </li>
            <li>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                onChange={onLoginInputChange}
                placeholder="Password*"
                autoComplete="current-password"
                className={
                  authStatus === SERVICE_STATUS.FAILED &&
                  (errorMsg.code === 'AUTH0006' || inputLoginError.password)
                    ? `error-Danger`
                    : ''
                }
              />
              {authStatus === SERVICE_STATUS.FAILED &&
                (errorMsg.code === 'AUTH0006' || inputLoginError.password) && (
                  <p className="error-msz">
                    {errorMsg.message || inputLoginError.password}
                  </p>
                )}
              <strong aria-hidden onClick={passwordToggle}>
                <ImageLoader className="eye-icon" name="eye.svg" />
              </strong>
            </li>
            <li>
              <Link to="/forgotPassword" className="forgotPass">
                Forgot Password?
              </Link>
            </li>
          </ul>

          <div className="btnSet">
            <button
              type="submit"
              className="btn btn-big"
              disabled={authStatus === SERVICE_STATUS.INPROGRESS}
            >
              Log In
            </button>
          </div>
        </form>
        
        <Copyright />
        
      </div>
    </>
  );
};

export default SignIn;
