import axios from "axios";
import AppConfig from "../AppConfig";

const instance = axios.create({
  // if using localAPI server comment line no:8 and use line no:7
  // if using production API server comment line no:7 and use line no:8
  // baseURL: AppConfig.localApiUrl,
  baseURL: AppConfig.charakaApiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
