import React from 'react';
import { IMAGE_CATEGORY } from '../utils/types';

const getImageEndpoint = (category) => {
  switch (category) {
    case IMAGE_CATEGORY.AVATAR64:
      return '/avatars/size_64';
    case IMAGE_CATEGORY.AVATAR128:
      return '/avatars/size_128';
    case IMAGE_CATEGORY.AVATAR256:
      return '/avatars/size_256';
    default:
      return '';
  }
};

const ImageLoader = ({
  name,
  category = IMAGE_CATEGORY.OTHERS,
  className = '',
  alt = '',
  title = '',
}) => {
  if (!name || name === '') return <div />;

  const src = require(`../assets/images${getImageEndpoint(category)}/${name}`);
  return <img src={src} className={className} alt={alt} title={title} />;
};

export default ImageLoader;
