import React, { useState, useEffect, useRef } from 'react';

const ProteinGeneTable = ({ proteingenes, selectedProteinGene: initialSelectedProteinGene, onSelectedProteinGeneChange, onClose,isLoading }) => {
  const modalRef = useRef(null);
  const [selectedProteinGene, setSelectedProteinGene] = useState(initialSelectedProteinGene);

  const handleRowClick = (id) => {
    setSelectedProteinGene(id);
    onSelectedProteinGeneChange(id); // Notify parent component of the selected protein gene
    onClose(); // Close the table after selection
  };

  useEffect(() => {
    if (selectedProteinGene !== initialSelectedProteinGene) {
      setSelectedProteinGene(initialSelectedProteinGene);
    }
  }, [initialSelectedProteinGene]);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto z-10"
      ref={modalRef}
    >
      <div className="bg-white lg:w-4/5 p-8 rounded-md max-h-svh">
        <div className="w-full flex justify-end">
          <button
            onClick={onClose}
            className="text-red-500 hover:text-red-700"
            style={{ fontSize: '1.5rem', fontWeight: 'bold', border: 'none', background: 'none', cursor: 'pointer' }}
          >
            &times;
          </button>
        </div>
        <h2 className="text-2xl font-semibold mb-4">Select Protein</h2>
        <div className="gene-data-grid">
        <div className="table-wrapper" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
          <table className="table-formating w-full">
            <thead>
              <tr>
                <th>Protein Id</th>
                <th>Title</th>
                <th>GI</th>
                <th>Accession</th>
              </tr>
            </thead>
            <tbody>
            {isLoading ? (
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center', padding: '10px' }}>
                      Loading...
                    </td>
                  </tr>
                ):
              proteingenes.length > 0 ? (
                proteingenes.map((proteinGene, index) => {
                  const { accessionVersion, gi, id, title } = proteinGene.data;
                  const proteinGeneId = id.label.trim();
                  const isSelected = selectedProteinGene === proteinGeneId;

                  return (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(proteinGeneId)}
                      style={{
                        backgroundColor: isSelected ? '#e0f7fa' : 'transparent',
                        cursor: 'pointer',
                      }}
                    >
                      <td>{proteinGeneId}</td>
                      <td>{title.label.trim()}</td>
                      <td>{gi.label.trim()}</td>
                      <td>{accessionVersion.label.trim()}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: 'center', padding: '10px' }}>
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ProteinGeneTable;
