// // store.js
// import { configureStore } from '@reduxjs/toolkit';
// import usersSlice from './usersSlice';
// import productsSlice from './productsSlice';

// const store = configureStore({
//   reducer: {
//     users: usersSlice,
//     products: productsSlice
//     // Add other reducers here if needed
//   },
// });

// export default store;


import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {thunk} from 'redux-thunk';

import authReducer from '../slices/auth';
// import plantReducer from '../slices/plant';
// import additiveReducer from '../slices/additive';
// import plantDetailReducer from '../slices/plantDetail';
import passwordReducer from '../slices/password';
import toastReducer from '../slices/toast';
// import dataGridReducer from '../slices/dataGrid';
// import additiveDetail from '../slices/additiveDetail';
// import overlayReducer from '../slices/Overlay';
// import userReducer from '../slices/user';
// import supplierReducer from '../slices/suppliers';
// import reportReducer from '../slices/report';
// import gene from '../slices/gene';
// import protein from '../slices/protein';
// import animalBasedReducer from '../slices/animalBased';
// import ultraProcessedReducer from '../slices/ultraProcessed';
// import additiveAssignmentReducer from '../slices/additiveAssignment';
// import dashboardReducer from '../slices/dashboard';
// import linkPreparationReducer from '../slices/linkPreparation';
import usersSlice from './usersSlice';
import productsSlice from './productsSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const appReducer = combineReducers({
  auth: authReducer,
  // plant: plantReducer,
  // additive: additiveReducer,
  // plantDetail: plantDetailReducer,
  password: passwordReducer,
  toast: toastReducer,
  // dataGrid: dataGridReducer,
  // additiveDetail: additiveDetail,
  // overlay: overlayReducer,
  // user: userReducer,
  // suppliers: supplierReducer,
  // report: reportReducer,
  // gene: gene,
  // protein: protein,
  // animalBased: animalBasedReducer,
  // ultraProcessed: ultraProcessedReducer,
  // additiveAssignment: additiveAssignmentReducer,
  // dashboard: dashboardReducer,
  // linkPreparation: linkPreparationReducer,
  users: usersSlice,
  products: productsSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    localStorage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;

