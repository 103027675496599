import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import SignIn from "./components/login/login";
import PrivateRoute from './utils/privateRoutes';
import ProductPage from './components/ProductPage';
import ForgotPassword from "./components/login/ForgotPassword";
import VerifyOtp from "./components/login/VerifyOtp";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Provider } from 'react-redux';
import store from './redux/store';
import { pdfjs } from 'react-pdf';
import EditProductForm from "./components/EditProductForm";
import AddProductForm from "./components/AddProductForm";
import { Toaster } from 'sonner';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function App() {


  return (
    <Provider store={store}>
      <div className="App">
      <Toaster position="top-center" richColors />
      <Router>
      <Navbar/> 

          <Routes>
            <Route path="/*" element={<SignIn />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/verifyotp" element={<VerifyOtp />} />
 {/* if CORS error occurs comment line no:35 and line no:39 do change in product page also*/}
            <Route path="/" element={<PrivateRoute />}>
            <Route path="/edit/:productId" element={<EditProductForm />} />
              <Route path="/productPage" element={<ProductPage />} />
              <Route path="/add" element={<AddProductForm />} />
            </Route>
          </Routes>
         
        </Router>
      </div>
    </Provider>
  );
}

export default App;


