import { fetcher } from '.';

export const changePassword = (password) => {
  return fetcher('/auth/change-password', {
    method: 'PUT',
    body: JSON.stringify(password),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {
        status: false,
        message: error,
      };
    });
};

export const forgotPassword = (email) => {
  return fetcher('/auth/forgot-password', {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {
        status: false,
        userDetails: null,
        message: error,
      };
    });
};

export const resetPassword = (resetDetails) => {
  return fetcher('/auth/reset-password', {
    method: 'POST',
    body: JSON.stringify(resetDetails),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {
        status: false,
        userDetails: null,
        message: error,
      };
    });
};
