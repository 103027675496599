import { fetcher } from '.';

export const login = (username, password) =>
  fetcher(`/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers required by the API
    },
    body: JSON.stringify({
      email: username,
      password,
    }),
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
      return {
        status: false,
        userDetails: null,
        message: error,
      };
    });






