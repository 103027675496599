import React, { useRef, useState, useEffect } from "react";
import Switch from "react-switch";
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page } from 'react-pdf';
import AppConfig from "../AppConfig";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { FaFilePdf } from "react-icons/fa";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import PDFViewer from "./PDFViewer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageSlider from "./ImageSlider";
import { Modal, Button, Toast } from 'react-bootstrap';
import ImageLoader from "./ImageLoader";
import GeneTable from "./GeneTable";
import ProteinGeneTable from "./ProteinGeneTable";
import { addProducts } from "../redux/productsSlice";
import { nanoid } from 'nanoid';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import apiService from "../services/apiService";
import '../styles/_dropdown.scss'
import stages from "../assets/data/stage";
import tools from "../assets/data/tools";
import hosts from "../assets/data/host";
import Footer from "./Footer";

const AddProductForm = () => {
  const [productName, setProductName] = useState("");
  const [stage, setStage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedHost, setSelectedHost] = useState(null);
  const [objective, setObjective] = useState("");
  const [teamLead, setTeamLead] = useState("");
  const [isTestProduct, setIsTestProduct] = useState(false);
  const [files, setFiles] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [showTeamMemberModal, setShowTeamMemberModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState(false);
  const modalRef = useRef(null);
  const fileInputRef = useRef(null);
  const pdfInputRef = useRef(null);
  const [tool, setTool] = useState("");
  const [gcPercentage, setGcPercentage] = useState('');
  const [caiScore, setCaiScore] = useState('');
  const [optimizedSequence, setOptimizedSequence] = useState('');
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [currentPDF, setCurrentPDF] = useState(null);
  const users = useSelector((store) => store.users);
  const userId = localStorage.getItem('userId');
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [commonName, setcommonName] = useState("");
  const [scientificName, setscientificName] = useState("");
  const [experimentName, setexperimentName] = useState("");
  const [targetGene, setTargetGene] = useState("");
  const [proteinGene, setProteinGene] = useState("");
  const [geneOptions, setGeneOptions] = useState([]);
  const [proteinGeneOptions, setProteinGeneOptions] = useState([])
  const [selectedGene, setSelectedGene] = useState('');
  const [selectedProteinGene, setSelectedProteinGene] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneTableOpen, setIsGeneTableOpen] = useState(false);
  const [isProteinTableOpen, setIsProteinTableOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [isExperimentModalOpen, setIsExperimentModalOpen] = useState(false);
  const [currentExperimentName, setCurrentExperimentName] = useState('');
  const navigate = useNavigate();


  const dispatch = useDispatch();
  useEffect(() => {
    if (teamLead?.value != userId) {
      setIsApproved(false);
      setSelectedSequence(false);
    }
  }, [teamLead, userId]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Filter out files that are not jpeg, png, or webp
    const allowedFiles = selectedFiles.filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp"
    );

    // Limit the number of files to a maximum of 10
    if (allowedFiles.length > 10) {
      alert("Maximum 10 files allowed.");
      return;
    }

    setFiles([...files, ...allowedFiles]);
    fileInputRef.current.value = null; // Clear the input value
  };

  const getContainerStyles = () => {
    return {
      width: "300px",
      height: imagesList.length > 0 ? "280px" : "0px",
      margin: "0 auto",
      transition: "height 0.3s ease",
    };
  };

  const handlePdfFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const allowedFiles = selectedFiles.filter((file) => file.type === "application/pdf");

    if (allowedFiles.length > 10) {
      alert("Maximum 10 PDF files allowed.");
      return;
    }
    setPdfFiles([...pdfFiles, ...allowedFiles]);
    pdfInputRef.current.value = null; // Clear the input value
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);

    // Filter out files that are not jpeg, png, or webp
    const allowedFiles = droppedFiles.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp"
    );

    // Limit the number of files to a maximum of 10
    if (files.length + allowedFiles.length > 10) {
      alert("Maximum 10 files allowed.");
      return;
    }

    setFiles([...files, ...allowedFiles]);
  };
  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const handleDeletePdf = (index) => {
    setPdfFiles((prevPdfFiles) => prevPdfFiles.filter((_, i) => i !== index));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handlePdfBrowseClick = () => {
    pdfInputRef.current.click();
  };
  const openPdf = (pdf) => {
    const pdfUrl = URL.createObjectURL(pdf);
    setSelectedPdfUrl(pdfUrl);
    setShowPDFViewer(true); // Open PDF viewer modal
  };
  const handleHostChange = (selectedOption) => {
    setSelectedHost(selectedOption);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const filenames = files.map((file) => file.name);
    const pdfFilenames = pdfFiles.map((file) => file.name);

    const experimentDetails = rows.map((row) => ({
      experimentName: row.experimentName,
      commonName: row.commonName,
      scientificName: row.scientificName,
      gene: row.selectedGene,
      protein: row.selectedProteinGene,
      tool: row.tool,
      caiScore: row.caiScore,
      gcPercentage: row.gcPercentage,
      optimizedSequence: row.optimizedSequence,
      selectedSequence: row.selectedSequence
    }));
    // Handle form submission
    const addFormData = {
      productName,
      stage,
      objective,
      teamLead: teamLead ? teamLead.value : "",
      isTestProduct,
      isApproved,
      selectedTeamMembers: selectedTeamMembers.map((member) => member.value),
      filenames,
      pdfFilenames: pdfFilenames,
      experimentDetails
    };

    // Create form data
    const formData = new FormData();
    const photosFormData = new FormData(); // Separate FormData for photos
    const pdfFormData = new FormData();
    formData.append("productName", productName);
    formData.append("stage", stage);
    formData.append("objective", objective);
    formData.append("teamLead", teamLead ? teamLead.value : "");
    formData.append("isTestProduct", isTestProduct ? 1 : 0);
    formData.append("isApproved", isApproved ? 1 : 0);
    selectedTeamMembers.forEach((member) => {
      formData.append("selectedTeamMembers[]", member.value);
    });


    selectedTeamMembers.forEach((member) => {

      formData.append("selectedTeamMembers[]", member);

    });


    files.forEach((file) => {
      formData.append("photos", file);
      photosFormData.append("photos", file);
    });
    pdfFiles.forEach((file) => {
      formData.append("pdfs", file);
      pdfFormData.append("pdfs", file);
    });

    setIsSubmitting(true);

    try {
      const productResponse = await apiService.post(
        `/product/add`,
        addFormData
      );
      const prod_id = productResponse.data.productId;
      addFormData.id = prod_id;

      // Check if upload was successful before proceeding with product submission
      if (productResponse.status === 201) {
        if (files.length > 0) {
          const uploadResponse = await apiService.post(
            `/photos/upload/${prod_id}`,
            photosFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Handle response
          if (uploadResponse.status === 200) {
            dispatch(addProducts(addFormData));
            // onProductAdded();
            // onClose();
          } else {
            throw new Error("Failed to upload photos");
          }
        } else {
          dispatch(addProducts(addFormData));
          // onProductAdded();
          // onClose();
        }
        if (pdfFiles.length > 0) {
          const uploadPdfResponse = await apiService.post(`/pdf/upload/${prod_id}`,
            pdfFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              },
            });

          if (uploadPdfResponse.status === 200) {
            dispatch(addProducts(addFormData));
            // onClose();
          } else {
            throw new Error("Failed to upload PDFs");
          }
        } else {
          dispatch(addProducts(addFormData));
          toast.success("Product added succesfully")
          navigate('/productPage');
          // onClose();
        }
      } else {
        throw new Error("Failed to add product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product")
      // Handle error if needed
    } finally {
      // Set isSubmitting to false after submission is completed (success or failure)
      setIsSubmitting(false);
    }
  };


  const handleToggleTeamMember = (member, e) => {
    // Stop event propagation
    e.stopPropagation();

    if (selectedTeamMembers.some((m) => m.id === member.id)) {
      setSelectedTeamMembers(selectedTeamMembers.filter((m) => m.id !== member.id));
    } else {
      setSelectedTeamMembers([...selectedTeamMembers, member.id]);
    }
  };

  const handleSwitchChange = (checked) => {
    setIsTestProduct(checked ? 1 : 0);
  };
  const handleChange = (selectedOption) => {
    setTeamLead(selectedOption);
  };
  const handleTeamMembersChange = (selectedOptions) => {
    setSelectedTeamMembers(selectedOptions);
  };
  const handleStageChange = (selectedOption) => {
    setStage(selectedOption ? selectedOption.value : "");
  };
  const handleToolsChange = (selectedOption) => {
    setTool(selectedOption ? selectedOption.value : "");
  };
  const handleOpenPDFModal = (pdfFile) => {
    const fileUrl = URL.createObjectURL(pdfFile);
    setCurrentPDF(fileUrl);
    setShowPDFModal(true);
  };

  const handleClosePDFModal = () => {
    setShowPDFModal(false);
    setCurrentPDF(null);
  };
  const onDeletePdf = (index) => {
    const updatedPdfFiles = [...pdfFiles];
    updatedPdfFiles.splice(index, 1);
    setPdfFiles(updatedPdfFiles);
  };
  const handleClosePDFViewer = () => {
    setSelectedPdfUrl(null);
    setShowPDFViewer(false);
  };
  const imagesList = files.map((file) => ({
    url: URL.createObjectURL(file),
    title: file.name,
  }));
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const toggleGeneTable = () => {
    setIsGeneTableOpen(prevState => !prevState);
  };

  const toggleProteinGeneTable = () => {
    setIsProteinTableOpen(prevState => !prevState);
  };
  const handleSearch = async (id) => {
    const index = rows.findIndex((value) => value.id === id);

    const currentSelectedGene = rows[index].selectedGene;
    if (currentSelectedGene) {
      setIsGeneTableOpen(true);
      setIsLoading(true);
      try {
        const response = await fetch(`${AppConfig.pfCharakaApiUrl}/gene?term=${encodeURIComponent(currentSelectedGene)}`);
        const data = await response.json();

        if (data.status && data.data && data.data.rows) {
          const filteredRows = data.data.rows.filter(row =>
            row.data.id.label.trim() !== '' && row.data.description.label.trim() !== ''
          );
          setGeneOptions(filteredRows);
        } else {
          setGeneOptions([]);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setGeneOptions([]);
      } finally {
        setIsLoading(false);
      }
      setCurrentRowIndex(index);
      // setIsGeneTableOpen(true);
    }
  };
  const handleSearchProtein = async (id) => {
    const index = rows.findIndex((value) => value.id === id);
    const currentSelectedProtein = rows[index].selectedProteinGene;
    if (currentSelectedProtein) {
      setIsProteinTableOpen(true);
      setIsLoading(true);
      try {
        const response = await fetch(`${AppConfig.pfCharakaApiUrl}/protein?term=${encodeURIComponent(currentSelectedProtein)}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        if (data.status && data.data && data.data.rows) {
          const filteredRows = data.data.rows.filter(row =>
            row.data.id.label.trim() !== '' &&
            row.data.title.label.trim() !== '' &&
            row.data.gi.label.trim() !== '' &&
            row.data.accessionVersion.label.trim() !== ''
          );
          setProteinGeneOptions(filteredRows);
        } else {
          setProteinGeneOptions([]);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setProteinGeneOptions([]);
      } finally {
        setIsLoading(false);
      }

      setCurrentRowIndex(index);
    } else {
      setProteinGeneOptions([]);
    }
  };


  const handleSelectedGeneChange = (geneId, index) => {
    setRows(prevRows =>
      prevRows.map((row, i) => (i === index ? { ...row, selectedGene: geneId } : row))
    );
    setIsGeneTableOpen(false);
  };
  const handleSelectedProteinGeneChange = (proteinGeneId, index) => {
    setRows(prevRows =>
      prevRows.map((row, i) => (i === index ? { ...row, selectedProteinGene: proteinGeneId } : row))
    );
    setIsProteinTableOpen(false);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      { id: nanoid(), experimentName: "", commonName: "", scientificName: "", selectedGene: "", selectedProteinGene: "" },
    ]);
    setShowFields(true);
  };
  const handleRemoveExperiment = (id) => {
    setRows((prevRows) => prevRows.filter(row => row.id !== id));
    if (rows.length <= 1) setShowFields(false); // Hide fields if no rows are left
  };
  const handleInputChange = (id, event) => {
    const { name, value } = event.target;
    setRows(prevRows =>
      prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
    );
  };
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const handleCloseGeneTableModal = () => {
    setIsGeneTableOpen(false);
  }
  const handleCloseProtienTableModal = () => {
    setIsProteinTableOpen(false);
  }
  const handleCodonOptimization = (rowId, experimentName) => {
    // Logic to open a modal or window
    setCurrentRowId(rowId);
    setCurrentExperimentName(experimentName);
    setIsExperimentModalOpen(true);  // Assuming you have a modal state
  };
  const handleCloseModal = () => {
    setIsExperimentModalOpen(false);
  };

  const handleSaveModal = () => {
    // Find the row by currentRowId and update its fields
    const updatedRows = rows.map(row => {
      if (row.id === currentRowId) {
        return {
          ...row,
          tool,
          caiScore,
          gcPercentage,
          optimizedSequence,
          selectedSequence
        };
      }
      return row;
    });

    setRows(updatedRows); // Update the state with the new row data
    handleCloseModal();   // Close the modal
  };


  return (
    <>
      <div className="main-container mx-auto p-8 max-w-7xl">

        <button
          type="button"
          onClick={handleBack} // Add handler function for going back
          className="border-gray-400 border text-gray-600 px-2 py-1 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center mb-4 text-sm w-auto"
          style={{ width: 'fit-content' }}
        >
          <ImageLoader name="left-arrow-active.svg" className="mr-1 h-4 w-3" />
          <span className="whitespace-nowrap">Back to products</span>
        </button>
        <h2 className="text-2xl font-semibold mb-4 sticky">Add Product</h2>

        <div className="flex flex-col min-h-screen">
          <form
            className="flex flex-col flex-grow"
            onSubmit={handleSubmit}
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
          >
            <div className="flex-grow overflow-y-auto">
              {currentStep === 1 && (

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {/* Left column */}
                  <div className="lg:col-span-1 grid grid-cols-2 gap-x-4 gap-y-1 auto-rows-min">
                    <div className="input-container col-span-2">
                      <label htmlFor="name" className="block font-bold mb-1">
                        Product Name:
                      </label>
                      <input
                        required
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter product name"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        className="border border-gray-300 p-2 rounded w-full"
                      />
                    </div>
                    <div className="col-span-2">
                      <label htmlFor="stage" className="block text-black-700 font-bold mb-1">
                        Stage:
                      </label>
                      <Select
                        id="stage"
                        name="stage"
                        value={stages.find((stageOption) => stageOption.value === stage)}
                        onChange={handleStageChange}
                        options={stages}
                        required
                      />
                    </div>
                    <div className="textarea-container col-span-2 mb-2 lg:mb-0">
                      <label htmlFor="objective" className="block text-gray-700 font-bold mb-1">
                        Objective:
                      </label>
                      <textarea
                        required
                        placeholder="Enter objective"
                        id="objective"
                        name="objective"
                        value={objective}
                        onChange={(e) => setObjective(e.target.value)}
                        className="border border-gray-300 p-2 rounded w-full"
                        rows={4} // Adjust rows as needed
                      />
                    </div>
                    <div className="flex flex-col col-span-2 mb-4 lg:mb-0">
                      <div className="mb-4">
                        <label htmlFor="teamLead" className="block text-black-700 font-bold mb-1">
                          Team Lead:
                        </label>
                        <Select
                          id="teamLead"
                          name="teamLead"
                          value={teamLead}
                          onChange={handleChange}
                          required
                          options={users.map((user) => ({
                            value: user.id,
                            label: user.name
                          }))}
                        />
                      </div>
                      <div>
                        <label htmlFor="teamMembers" className="block text-black-700 font-bold mb-1">
                          Product Team Members:
                        </label>
                        <Select
                          id="teamMembers"
                          name="teamMembers"
                          value={selectedTeamMembers}
                          onChange={handleTeamMembersChange}
                          isMulti
                          options={users.map((user) => ({
                            value: user.id,
                            label: user.name,
                          }))}
                        />
                      </div>
                    </div>
                    <div className="mb-4 col-span-1 flex flex-col">
                      <label htmlFor="isTestProduct" className="block font-bold mb-1 mr-4">
                        Is Test Product:
                      </label>
                      <Switch
                        checked={isTestProduct === 1}
                        onChange={handleSwitchChange}
                        onColor="#7FB541"
                        onHandleColor="#699F40"
                        handleDiameter={25}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={26}
                        width={48}
                        className="react-switch"
                      />
                    </div>
                  </div>
                  {/* Right column */}
                  <div className="lg:col-span-1 flex flex-col gap-y-1">
                    <label htmlFor="attachPhoto" className="block font-bold mb-1 mr-4">
                      Attach Photo:
                    </label>
                    <button
                      type="button"
                      onClick={handleBrowseClick}
                      className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
                    >
                      Upload Photos
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept=".jpg,.png,.webp"
                      multiple
                      onChange={handleFileChange}
                      className="hidden"
                    />
                    <div className="my-3 flex flex-wrap">
                      {files.length === 0 && (
                        <p className="text-green-700 text-xs" style={{ color: "#7FB541" }}>
                          Up to 10 files are allowed to be uploaded with the maximum
                          file size of 20MB per file. Extensions allowed are .jpeg,
                          .png, and .webp.
                        </p>
                      )}
                      <div style={getContainerStyles()}>
                        <ImageSlider imagesList={imagesList} />
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {files.map((file, index) => (
                        <div key={index} className="relative">
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="w-20 h-20 object-cover"
                          />
                          <button
                            type="button"
                            className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                            onClick={() => handleDeleteFile(index)}
                          >
                            <IoCloseOutline />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                </div>
              )}


              {currentStep === 2 && (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {/* Left Column */}
                  <div className="col-span-1 grid-cols-1 gap-4">
                    {/* Host Organism */}
                    <div className="h-fit">
                      <label htmlFor="stage" className="block text-black-700 font-bold mb-1">
                        Host:
                      </label>
                      <Select
                        id="host"
                        name="host"
                        value={selectedHost}
                        onChange={handleHostChange}
                        options={hosts.map((option) => ({
                          value: option.value,
                          label: option.label,
                        }))}
                      />
                      <br />
                    </div>
                    {teamLead?.value == userId && (
                      <div className="mt-3">
                        <div className="checkbox">
                          <input
                            type="checkbox"
                            id="isApproved"
                            name="isApproved"
                            checked={isApproved}
                            onChange={(e) => setIsApproved(e.target.checked)}
                          />
                          <label htmlFor="isApproved" className="block text-gray-700 font-bold mb-2">
                            Approve Product:
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Right Column */}
                  <div className="col-span-1 grid grid-cols-1 gap-4">
                    <div>
                      <label htmlFor="attachPdf" className="block font-bold mb-1 mr-4">
                        Attach PDF:
                      </label>
                      <button
                        type="button"
                        onClick={handlePdfBrowseClick}
                        className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
                      >
                        Upload PDFs
                      </button>
                      <input
                        type="file"
                        id="pdfUpload"
                        name="pdfUpload"
                        ref={pdfInputRef}
                        onChange={handlePdfFileChange}
                        className="hidden"
                        accept="application/pdf"
                        multiple
                      />
                      <div className="flex flex-wrap gap-2 mt-1">
                        {pdfFiles.map((pdf, index) => (
                          <div key={index} className="flex items-center mb-1">
                            <FaFilePdf className="text-red-500 mr-1" />
                            <span className="underline cursor-pointer" onClick={() => openPdf(pdf)}>
                              {pdf.name}
                            </span>
                            <button
                              type="button"
                              className="bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                              onClick={() => handleDeletePdf(index)}
                            >
                              <IoCloseOutline />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>

                  </div>
                </div>
              )}


              {currentStep === 3 && (
                <div>
                  <>
                    {/* Buttons Section */}
                    <div className="flex justify-end items-center mb-4">
                      <button
                        type="button"
                        onClick={handleAddRow}
                        className="text-green-600 hover:text-green-700 px-3 py-2 rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                      >
                        <ImageLoader name="plus-icon.svg" className="h-6 w-6 mr-2" alt="Add" title="Add" />
                        Add Experiment
                      </button>


                    </div>
                    {showFields && (
                      <div className="grid grid-cols-1 gap-4">
                        <div className="grid grid-cols-6 gap-4 items-center font-bold">
                          <div className="block font-bold mb-1">Experiment Name</div>
                          <div className="block font-bold mb-1">Common Name</div>
                          <div className="block font-bold mb-1">Scientific Name</div>
                          <div className="block font-bold mb-1">Gene</div>
                          <div className="block font-bold mb-1">Protein</div>
                          <div className="block font-bold mb-1">Action</div>
                        </div>
                        {rows.map((row, index) => (
                          <div key={row.id} className="grid grid-cols-6 gap-4 items-center cursor-pointer hover:bg-gray-100 p-2 rounded">
                            {/* Experiment Name */}
                            <div className="input-container col-span-1">
                              {/* <label htmlFor={`experimentName-${row.id}`} className="block font-bold mb-1">
                                Experiment Name:
                              </label> */}
                              <input
                                type="text"
                                id={`experimentName-${row.id}`}
                                name="experimentName"
                                placeholder="Enter experiment name"
                                value={row.experimentName}
                                onChange={(e) => handleInputChange(row.id, e)}
                                required
                                className="border border-gray-300 p-2 rounded w-full"
                              />
                            </div>

                            {/* Common Name */}
                            <div className="input-container col-span-1">
                              {/* <label htmlFor={`commonName-${row.id}`} className="block font-bold mb-1">
                                Common Name:
                              </label> */}
                              <input
                                type="text"
                                id={`commonName-${row.id}`}
                                name="commonName"
                                placeholder="Enter common name"
                                value={row.commonName}
                                onChange={(e) => handleInputChange(row.id, e)}
                                required
                                className="border border-gray-300 p-2 rounded w-full"
                              />
                            </div>

                            {/* Scientific Name */}
                            <div className="input-container col-span-1">
                              {/* <label htmlFor={`scientificName-${row.id}`} className="block font-bold mb-1">
                                Scientific Name:
                              </label> */}
                              <input
                                type="text"
                                id={`scientificName-${row.id}`}
                                name="scientificName"
                                placeholder="Enter scientific name"
                                value={row.scientificName}
                                onChange={(e) => handleInputChange(row.id, e)}
                                required
                                className="border border-gray-300 p-2 rounded w-full"
                              />
                            </div>

                            {/* Target Gene */}
                            <div className="input-container col-span-1 relative">
                              {/* <label htmlFor={`targetGene-${row.id}`} className="block font-bold mb-1">
                                Gene:
                              </label> */}
                              <div className="flex">
                                <input
                                  type="text"
                                  id={`targetGene-${row.id}`}
                                  name="selectedGene"
                                  placeholder="Search for a gene"
                                  value={row.selectedGene}
                                  onChange={(e) => handleInputChange(row.id, e)}
                                  required
                                  className="border border-gray-300 p-2 rounded-l w-full"
                                />
                                <button
                                  type="button"
                                  onClick={() => handleSearch(row.id)}
                                  className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"
                                  style={{
                                    backgroundColor: '#6EB724',
                                    borderRadius: '0 4px 4px 0',
                                    width: '2.5rem',
                                    height: '2.7rem',
                                    marginTop: '1px'
                                  }}
                                >
                                  <ImageLoader
                                    className="imgFirst img-fluid"
                                    name="search-icon.svg"
                                    alt="Search"
                                    title="Search"
                                    style={{ width: '1.25rem', height: '1.25rem' }}
                                  />
                                </button>
                              </div>
                            </div>

                            {/* Protein Gene */}
                            <div className="input-container col-span-1 relative">
                              {/* <label htmlFor={`proteinGene-${row.id}`} className="block font-bold mb-1">
                                Protein:
                              </label> */}
                              <div className="flex items-center">
                                <div className="flex relative w-full">
                                  <input
                                    type="text"
                                    id={`proteinGene-${row.id}`}
                                    name="selectedProteinGene"
                                    placeholder="Search for protein"
                                    value={row.selectedProteinGene}
                                    onChange={(e) => handleInputChange(row.id, e)}
                                    required
                                    className="border border-gray-300 p-2 rounded-l w-full"
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleSearchProtein(row.id)}
                                    className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"
                                    style={{
                                      backgroundColor: '#6EB724',
                                      borderRadius: '0 4px 4px 0',
                                      width: '2.5rem',
                                      height: '2.75rem',
                                    }}
                                  >
                                    <ImageLoader
                                      className="imgFirst img-fluid"
                                      name="search-icon.svg"
                                      alt="Search"
                                      title="Search"
                                      style={{ width: '1.25rem', height: '1.25rem' }}
                                    />
                                  </button>
                                </div>

                              </div>
                            </div>
                            <div className="input-container col-span-1 flex items-center justify-between">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleCodonOptimization(row.id, row.experimentName);
                                }}
                                className="text-green-600 hover:text-green-700 px-2 py-1 text-sm rounded-full border border-green-600 hover:border-green-700 inline-flex items-center"
                              >
                                Codon Optimization
                              </a>
                              <button
                                type="button"
                                onClick={() => handleRemoveExperiment(row.id)}
                                className="p-2 border-none flex items-center justify-center"
                                style={{
                                  backgroundColor: 'transparent',
                                  color: '#FF0000',
                                  width: '2.5rem',
                                  height: '2.7rem',
                                }}
                              >
                                <ImageLoader
                                  className="imgFirst img-fluid"
                                  name="crossGray copy.svg"
                                  alt="Remove"
                                  title="Remove"
                                  style={{ width: '1.25rem', height: '1.25rem' }}
                                />
                              </button>
                            </div>
                          </div>
                          // </div>
                        ))}

                        {/* Collapsible Gene Table */}
                        {isGeneTableOpen && (
                          <GeneTable
                            genes={geneOptions}
                            selectedGene={rows[currentRowIndex]?.selectedGene || ''}
                            onSelectedGeneChange={(geneId) => handleSelectedGeneChange(geneId, currentRowIndex)}
                            // onCloseTable={() => setIsGeneTableOpen(false)}
                            onClose={handleCloseGeneTableModal}
                            isLoading={isLoading}
                          />
                        )}

                        {/* {selectedProduct && (
        <EditProductForm
          product={selectedProduct}
          productPhotos={productPhotos}
          productPdfs={productPdfs}
          onClose={handleCloseEditModal}
          onProductEdited={onProductEdited} />
      )} */}

                        {/* Collapsible Protein Gene Table */}
                        {isProteinTableOpen && (
                          <div className="relative">
                            <ProteinGeneTable
                              proteingenes={proteinGeneOptions}
                              selectedProteinGene={rows[currentRowIndex]?.selectedProteinGene || ''}
                              onSelectedProteinGeneChange={(proteinGeneId) => handleSelectedProteinGeneChange(proteinGeneId, currentRowIndex)}
                              onClose={handleCloseProtienTableModal}
                              isLoading={isLoading}
                            />
                          </div>
                        )}

                      </div>
                    )}
                  </>
                </div>
              )}
            </div>

            {/* Buttons Section at the Bottom */}
            <div className="sticky bottom-0 left-0 right-0 bg-white p-4 flex justify-end items-center">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center mr-4"
                >
                  <ImageLoader name="left-arrow-active.svg" className="mr-2 h-6 w-4" />
                  Previous
                </button>
              )}

              {currentStep < 3 && (
                <button
                  type="button"
                  onClick={handleNextStep}
                  className={`border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center ${currentStep > 1 ? 'mr-4' : ''}`}
                >
                  <ImageLoader name="right-arrow-active.svg" className="mr-2 h-6 w-4" />
                  Next
                </button>
              )}

              {currentStep === 3 && (
                <button
                  type="submit"
                  className="text-white px-4 py-2 rounded flex items-center"
                  disabled={isSubmitting}
                  style={{ background: '#7FB541', borderColor: 'transparent' }}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              )}
            </div>



          </form>

        </div>

      </div>
      <Footer></Footer>
      {isExperimentModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg w-1/2">
            <div className="mb-4">
              <h2 className="text-xl font-bold">{currentExperimentName}</h2>
              <div className="col-span-2">
                <label htmlFor="tool" className="block text-black-700 font-bold mb-1">
                  Tool
                </label>
                <Select
                  id="tool"
                  name="tool"
                  value={tools.find((toolOption) => toolOption.value === tool)}
                  onChange={(selectedOption) => setTool(selectedOption.value)}
                  options={tools}
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-2">CAI score</label>
              <input
                type="number"
                className="border border-gray-300 p-2 rounded w-full"
                value={caiScore}
                onChange={(e) => setCaiScore(e.target.value)}
                placeholder="Enter CAI score"
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-2">GC%</label>
              <input
                type="number"
                className="border border-gray-300 p-2 rounded w-full"
                value={gcPercentage}
                onChange={(e) => setGcPercentage(e.target.value)}
                placeholder="Enter GC percentage"
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-2">Optimised sequence</label>
              <textarea
                className="border border-gray-300 p-2 rounded w-full"
                value={optimizedSequence}
                onChange={(e) => setOptimizedSequence(e.target.value)}
                placeholder="Paste optimised sequence"
                onKeyDown={(e) => {
                  // Allow only Ctrl+V (paste), Ctrl+Z (undo), and navigation keys (e.g., arrows, Home, End)
                  if (
                    (e.ctrlKey || e.metaKey) && (e.key === 'v' || e.key === 'z') || // Allow Ctrl+V and Ctrl+Z
                    e.key === 'Backspace' || e.key === 'Delete' || // Allow Backspace and Delete for clearing
                    e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || // Allow arrow keys for navigation
                    e.key === 'Home' || e.key === 'End' // Allow Home and End for navigation
                  ) {
                    return; // Allow these keys
                  } else {
                    e.preventDefault(); // Prevent all other typing
                  }
                }}
                onPaste={(e) => {
                  e.stopPropagation(); // Allow pasting
                }}
              />
            </div>



            {teamLead?.value == userId && (
              <div className="mb-6">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="selectedSequence"
                    name="selectedSequence"
                    checked={selectedSequence}
                    onChange={(e) => setSelectedSequence(e.target.checked)}
                  />
                  <label htmlFor="selectedSequence" className="block text-gray-700 font-bold mb-2">Selected sequence</label>
                </div>
              </div>
            )}
            <div className="flex justify-end">
              <button
                type="button"
                className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSaveModal}
                className="p-2 w-24 font-bold text-white rounded"
                style={{
                  background: '#7FB541',
                  borderColor: 'transparent',
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

    </>

  );

};


export default AddProductForm;
