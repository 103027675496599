// src/slices/password.js
import { createSlice } from '@reduxjs/toolkit';
import * as PasswordService from '../services/password';
import { SERVICE_STATUS } from '../utils/types';
import { toastNotifyError, toastNotifySucess } from './toast';

const initialState = {
  status: SERVICE_STATUS.IDLE,
  errorMsg: '',
  successMsg: '',
  email: '',
  passwordErrors: {},
};

export const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMsg = '';
      state.successMsg = '';
      state.passwordErrors = {};
    },
    passwordInProgress: (state) => {
      state.errorMsg = '';
      state.successMsg = '';
      state.status = SERVICE_STATUS.INPROGRESS;
    },
    forgotPasswordSuccess: (state, action) => {
      state.successMsg = '';
      state.errorMsg = '';
      state.email = action.payload;
      state.status = SERVICE_STATUS.SUCCESS;
    },
    forgotPasswordFailed: (state, action) => {
      state.successMsg = action.payload;
      state.errorMsg = action.payload;
      state.status = SERVICE_STATUS.FAILED;
    },
    resetPasswordSuccess: (state, action) => {
      state.successMsg = action.payload;
      state.errorMsg = '';
      state.status = SERVICE_STATUS.SUCCESS;
    },
    resetPasswordFailed: (state, action) => {
      state.successMsg = '';
      state.errorMsg = !action.payload.data ? action.payload.message : '';
      state.passwordErrors = action.payload.data;
      state.status = SERVICE_STATUS.FAILED;
    },
    changePasswordFailed: (state, action) => {
      state.errorMsg = '';
      state.successMsg = '';
      state.passwordErrors = action.payload;
      state.status = SERVICE_STATUS.FAILED;
    },
    changePasswordSucceeded: (state, action) => {
      state.errorMsg = '';
      state.successMsg = action.payload;
      state.passwordErrors = {};
      state.status = SERVICE_STATUS.SUCCESS;
    },
  },
});

export const {
  clearError,
  passwordInProgress,
  forgotPasswordSuccess,
  forgotPasswordFailed,
  resetPasswordFailed,
  resetPasswordSuccess,
  changePasswordFailed,
  changePasswordSucceeded,
} = passwordSlice.actions;

export const changePassword = (password) => async (dispatch) => {
  dispatch(passwordInProgress());
  const response = await PasswordService.changePassword(password);
  if (!response.status && response.data) {
    const changePasswordErrors = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };

    response.data.forEach((errorMsg) => {
      changePasswordErrors[errorMsg.param] = errorMsg.msg;
    });

    dispatch(toastNotifyError(response.message, response.data));
    dispatch(changePasswordFailed(changePasswordErrors));
  } else {
    dispatch(changePasswordSucceeded(response.message));
    dispatch(toastNotifySucess(response.message));
  }
};

export const forgotPassword = (email, callback) => async (dispatch) => {
  dispatch(passwordInProgress());
  const response = await PasswordService.forgotPassword(email);
  if (!response.status) {
    dispatch(forgotPasswordFailed(response.message));
  } else {
    dispatch(forgotPasswordSuccess(email));
    callback();
  }
};

export const resetPassword = (resetDetails, callback) => async (dispatch) => {
  dispatch(passwordInProgress());
  const response = await PasswordService.resetPassword(resetDetails);
  if (!response.status) {
    dispatch(resetPasswordFailed(response));
  } else {
    dispatch(resetPasswordSuccess(response.message));
    callback();
  }
};

export default passwordSlice.reducer;
