const tools = [
    { value: "Vectot Builder", label: "Vectot Builder" },
    { value: "Benchling", label: "Benchling" },
    { value: "GeneScript", label: "GeneScript" },
    { value: "IDT", label: "IDT" },
    { value: "Geneius", label: "Geneius" },
    { value: "Twist Biosciences", label: "Twist Biosciences"},
    { value: "Thermo fisher Geneart", label: "Thermo fisher Geneart" }
  ];
  
  export default tools;
  