import React, { useState, useEffect, useRef } from 'react';

const GeneTable = ({ genes, selectedGene, onSelectedGeneChange, onClose,isLoading }) => {
  const modalRef = useRef(null);
  const [localSelectedGene, setLocalSelectedGene] = useState(selectedGene);

  const handleRowClick = (id) => {
    setLocalSelectedGene(id);
    onSelectedGeneChange(id); // Notify parent component of the selected gene
    onClose(); // Close the modal
  };

  useEffect(() => {
    // Update local state if selectedGene prop changes
    if (selectedGene !== localSelectedGene) {
      setLocalSelectedGene(selectedGene);
    }
  }, [selectedGene]);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto z-10"
      ref={modalRef}
    >
      <div className="bg-white lg:w-4/5 p-8 rounded-md max-h-svh">
        <div className="w-full flex justify-end">
          <button
            onClick={onClose}
            className="text-red-500 hover:text-red-700"
            style={{ fontSize: '1.5rem', fontWeight: 'bold', border: 'none', background: 'none', cursor: 'pointer' }}
          >
            &times;
          </button>
        </div>
        <h2 className="text-2xl font-semibold mb-4">Select Gene</h2>
        <div className="gene-data-grid">
          <div className="table-wrapper">
            <table className="table-formating w-full">
              <thead className="thead">
                <tr>
                  <th>Gene Id</th>
                  <th>Description</th>
                  <th>Location</th>
                  <th>Aliases</th>
                </tr>
              </thead>
              <tbody>
              {isLoading ? (
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center', padding: '10px' }}>
                      Loading...
                    </td>
                  </tr>
                ):
                genes.length > 0 ? (
                  genes.map((gene, index) => {
                    const { id, description, location, aliases } = gene.data;
                    const geneId = id.label.trim();
                    const isSelected = localSelectedGene === geneId;

                    return (
                      <tr
                        key={index}
                        onClick={() => handleRowClick(geneId)}
                        style={{
                          backgroundColor: isSelected ? '#e0f7fa' : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <td>{geneId}</td>
                        <td>{description.label.trim()}</td>
                        <td>{location.label ? location.label.trim() : 'N/A'}</td>
                        <td>{aliases.label ? aliases.label.trim() : 'N/A'}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center', padding: '10px' }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneTable;
